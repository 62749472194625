import { FC } from "react";
import { Box, CircularProgress } from "@mui/material";
import commonStyles from '../../styles.module.scss';
import styles from './styles.module.scss';
import { useAppSelector } from '../../utils/hooks';
import Home from '../../components/Home';

interface Props {
  moduleName?: string;
  isLoading?: boolean;
}

const HomePage: FC<Props> = ({ isLoading }: Props): JSX.Element => {
  const { firstName, lastName } = useAppSelector((state) => state.auth);
  if (isLoading) {
    return (
      <div className={commonStyles.customLoading}>
        <CircularProgress size={60}/>
      </div>
    );
  }

  return (
    <>
      <div className={commonStyles.moduleMainContainer}>
        <Box className={`${commonStyles.moduleContainer} ${styles.box}`} alignItems="center">        
          <h2>Welcome {firstName} {lastName}</h2>
          <p>Please select which application you wish to administer.</p>
            <Home></Home>
        </Box>
      </div>
    </>
  );
};

export default HomePage;
