import React, { useEffect, useState } from "react";
import { useAppSelector } from "../utils/hooks";
import { styled } from "@mui/material/styles";
import styles from "./Main.module.scss";

const drawerWidth = 240;

const MainContainer = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})<{ open?: boolean }>(({ theme, open }) => ({
  backgroundColor: "#000000",
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: `+${drawerWidth}px`,
  }),
}));

const withMain = (WrappedComponent: React.ComponentType) => {
  return (props: any) => {
    const isMenuOpen = useAppSelector((state) => state.application.isMenuOpen);
    const [open, setOpen] = useState(false);

    useEffect(() => {
      setOpen(isMenuOpen);
    }, [isMenuOpen]);

    return (
      <MainContainer open={open} className={styles["main"]}>
        <WrappedComponent {...props} />
      </MainContainer>
    );
  };
};

export default withMain;
