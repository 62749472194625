import React from "react";
import styles from "./OptionMenu.module.scss";
import { Menu, Box } from '@mui/material';

interface OptionMenuProps {
  children?: React.ReactNode;
  anchorEl: HTMLElement | null;
  open: boolean;
  handleClose: () => void;
}

const OptionMenu: React.FC<OptionMenuProps> = ({
  children,
  anchorEl,
  open,
  handleClose
}) => {

  return (
    <Menu
        className={styles["option-menu"]}
        MenuListProps={{
            'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
    >
        <Box>
            { children }
        </Box>
    </Menu>
  );
};

export default OptionMenu;