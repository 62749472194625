import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { lightTheme } from '../../../utils/theme/theme';
import styles from './ArticleRenderer.module.scss';
import { ArticleComponentType } from '../../../utils/constants/constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { getLangData } from '../../../utils/generic';

interface ArticleRendererProps {
    components: {
        component: JSX.Element | null;
        key: string;
    }[];
    getComponentRef: (key: string) => React.MutableRefObject<any> | undefined;
    imageSelectorRef: React.MutableRefObject<any>;
    titleTextInputRef: React.MutableRefObject<any>;
    subTitleTextInputRef: React.MutableRefObject<any>;
    updateCount: number;
}

const ArticleRenderer: React.FC<ArticleRendererProps> = ({
    components,
    getComponentRef,
    imageSelectorRef,
    titleTextInputRef,
    subTitleTextInputRef,
    updateCount }) => {
    const [renderedComponents, setRenderedComponents] = useState<JSX.Element[]>([]);
    const language = useSelector((state: RootState) => state.article.languagePreview);

    useEffect(() => {
        const updatedComponents: JSX.Element[] = components
            .map(({ key }) => {
                const ref = getComponentRef(key);
                if (ref && ref.current) {
                    const data = ref.current.getData();
                    if(!data || !(Object.keys(data.content).length && data.content[language])){
                        return null;
                    }
                    const content = data.content ? data.content : {};

                    switch (data.type) {
                        case ArticleComponentType.TEXT:
                            return (
                                <div
                                    key={key}
                                    dangerouslySetInnerHTML={{ __html: getLangData(data.content, language) || '' }}
                                />
                            );
                        case ArticleComponentType.VIDEO:
                            return (
                                <div key={key}>                                   
                                    {Object.keys(content).length && data?.content[language] && (
                                        <video
                                            controls={data.attributes.controls}
                                            autoPlay={data.attributes.autoplay}
                                            loop={data.attributes.loop}
                                            muted={data.attributes.muted}
                                            poster={data?.attributes?.poster[language]}
                                            src={data?.attributes?.src[language]}
                                        >
                                            Your browser does not support the video tag.
                                        </video>
                                    )}
                                </div>
                            );
                        case ArticleComponentType.IMAGE:
                            return (
                                <div key={key}>
                                    {Object.keys(content).length && (
                                        <img
                                            src={data?.attributes?.src[language]}
                                            alt={data?.attributes?.alt[language]}
                                        />)}
                                </div>
                            );
                        case ArticleComponentType.TASK:
                            return (
                                <div
                                    key={key}
                                    dangerouslySetInnerHTML={{ __html: getLangData(data.content, language) || '' }}
                                />
                            );
                        case ArticleComponentType.SURVEY:
                            return (
                                <div
                                    key={key}
                                    dangerouslySetInnerHTML={{ __html: getLangData(data.content, language) || '' }}
                                />
                            );
                        case ArticleComponentType.LINK:
                            return (
                                <div key={key}>
                                    {
                                        Object.keys(content).length && content[language] &&
                                        (
                                            <a
                                                rel={data?.attributes?.rel}
                                                target={data?.attributes?.target}
                                                href={content[language]}
                                            >
                                                {data?.attributes?.title[language]}
                                            </a>
                                        )
                                    }
                                </div>
                            );
                        default:
                            return null;
                    }
                }
                return null;
            })
            .filter((element): element is JSX.Element => element !== null);

        setRenderedComponents(updatedComponents);
    }, [components, getComponentRef, updateCount, language]);

    return (
        <ThemeProvider theme={lightTheme}>
            <div className={styles['article-renderer']}>
                {
                    getLangData(titleTextInputRef?.current?.getData()?.content, language) && (
                        <div className={styles['title']}>
                            {getLangData(titleTextInputRef?.current?.getData()?.content, language)}
                        </div>
                    )
                }

                {
                    getLangData(subTitleTextInputRef?.current?.getData()?.content, language) && (
                        <div className={styles['sub-title']}>
                            {getLangData(subTitleTextInputRef?.current?.getData()?.content, language)}
                        </div>
                    )
                }

                {
                    getLangData(imageSelectorRef?.current?.getData()?.content, language) && (
                        <div>
                            <img
                                src={getLangData(imageSelectorRef.current.getData().content, language)}
                                alt={getLangData(imageSelectorRef.current.getData().attributes?.alt, language)}
                            />
                        </div>
                    )
                }

                {renderedComponents.length > 0 &&
                    <div className={styles['selected-components']}>
                        {renderedComponents}
                    </div>
                }
            </div>
        </ThemeProvider>
    );
};

export default ArticleRenderer;
