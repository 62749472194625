import React from "react";
import styles from "./ListArticles.module.scss";
import CheckCircleOutlineSharpIcon from "@mui/icons-material/CheckCircleOutlineSharp";
import HistorySharpIcon from "@mui/icons-material/HistorySharp";
import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import SaveAsSharpIcon from "@mui/icons-material/SaveAsSharp";

interface ArticleStatusProps {
  status: string;
}

const ArticleStatus: React.FC<ArticleStatusProps> = ({ status }) => {
  const camelCaseStatus = status[0].toUpperCase() + status.slice(1);
  const lowerStatus = status.toLowerCase();
  return (
    <div className={styles["status"]}>
      {lowerStatus === "pending" && (
        <HistorySharpIcon className={styles["status-red"]} />
      )}
      {lowerStatus === "draft" && (
        <SaveAsSharpIcon className={styles["status-blue"]} />
      )}
      {lowerStatus === "approved" && (
        <CheckCircleOutlineSharpIcon className={styles["status-green"]} />
      )}
      <div>{camelCaseStatus}</div>
    </div>
  );
};

export default ArticleStatus;
