import React, { useRef, useEffect, useState, useCallback } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store';
import { v4 as uuidv4 } from 'uuid';

export const useDidUpdateEffect = (fn: any, inputs: Array<any>): void => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) fn();
    else didMountRef.current = true;
  }, [inputs, fn]);
};

export const useViewport = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return { width };
};

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default function useTimeout(callback: any, delay: number) {
  const callbackRef = useRef(callback);
  const timeoutRef = useRef<number>();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const set = useCallback(() => {
    timeoutRef.current = window.setTimeout(() => callbackRef.current(), delay);
  }, [delay]);

  const clear = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
  }, []);

  useEffect(() => {
    set();
    return clear;
  }, [delay, set, clear]);

  const reset = useCallback(() => {
    clear();
    set();
  }, [clear, set]);

  return { reset, clear };
}

export const useDebounce = (callback: any, delay: number, dependencies: any) => {
  const { reset, clear } = useTimeout(callback, delay);
  useEffect(reset, [...dependencies, reset]);
  useEffect(clear);
};

// Custom hook to manage refs for dynamically created components
export const useComponentRefs = () => {
  const componentRefs = useRef<{ [key: string]: React.MutableRefObject<any> }>({});

  const useAddComponentRef = () => {
    const key = uuidv4();
    const ref = React.createRef();
    componentRefs.current[key] = ref;
    return { key, ref };
  };

  const useGetComponentRef = (key: string) => {
    return componentRefs.current[key];
  };

  return { useAddComponentRef, useGetComponentRef };
};
