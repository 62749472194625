import { FC } from 'react';
import styles from './styles.module.scss';

const PreLogoutPage: FC = (): JSX.Element => {
  return (
    <div className={styles.preLogoutMessage}>
      Please wait while we securely log you out. Thank you for your patience.
    </div>
  );
}
export default PreLogoutPage;