import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Box, IconButton, Typography, LinearProgress } from "@mui/material";
import styles from "./EditAsset.module.scss";
import TextField from '@mui/material/TextField';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import InfoIcon from '@mui/icons-material/Info';
import MenuItem from '@mui/material/MenuItem';
import FlipOutlinedIcon from '@mui/icons-material/FlipOutlined';
import { Rotate90DegreesCcw } from "@mui/icons-material";
import { IAssetItem, IAssetUpdateItem } from "../../utils/interfaces/assets";
import OptionMenu from "../OptionMenu/OptionMenu";
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import { rotateAsset } from "../../utils/helpers/common";
import { ASSET_TYPE_IMAGE, ASSET_TYPE_VIDEO } from "../../utils/constants/constants";

interface EditAssetProps {
    asset: IAssetItem;
    onFileDataChange: any;
    singleUploadProgress?:any;
    isSingleUpload?: boolean;
    isNew?: boolean;
}

const EditAsset = ({
    asset,
    onFileDataChange,
    singleUploadProgress,
    isSingleUpload = false,
    isNew = false,
}: EditAssetProps) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [fileData, setFileData] = useState<IAssetUpdateItem>(
        {
            file: asset.file,
            assetPublicId: asset.assetPublicId,
            caption: asset.title,
            alt: asset.alt,
            tags: asset.tags.split(',') as never[],
            rotate: asset.rotate ? asset.rotate : 90,
            mirror: asset.mirror ? asset.mirror : false,
        }
    );
    const [isMirrored, setIsMirrored] = useState<boolean>(false);
    const [rotationDegree, setRotationDegree] = useState<number>(0);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const [assetSrc, setAssetSrc] = useState('');
    const [editedAssetData, setEditedAssetData] = useState<IAssetItem>(asset);

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const uploadUrl = asset.file ? URL.createObjectURL(asset.file as File) : asset.url;
        setAssetSrc(uploadUrl);
    }, [asset])

    useEffect(() => {
        setRotationDegree(0);
        setIsMirrored(asset.mirror);
        setFileData(
            {
                file: asset.file,
                assetPublicId: asset.assetPublicId,
                caption: asset.title,
                alt: asset.alt,
                tags: asset.tags.split(',') as never[],
                rotate: asset.rotate ? asset.rotate : 0,
                mirror: asset.mirror,
            }
        )
    }, [])

    useEffect(() => {
        onFileDataChange({
          ...fileData,
          rotate: (fileData?.rotate ?? asset.rotate),
        });
    }, [fileData]);

    useEffect(() => {
        videoRef.current?.load();
    }, [editedAssetData.thumbnail, editedAssetData.url]);

    const handleChangeAsset = async (e: ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement> | any,
        selectedFile?: FileList | File[]) => {
        setAnchorEl(null);
        setIsMirrored(false);
        setRotationDegree(0);

        if (fileInputRef.current?.files) {
            let file = selectedFile && selectedFile.length > 0 ? selectedFile : e.target.files;
            file = file[0];
            const [type] = file.type.split("/");
            if (type === ASSET_TYPE_VIDEO) {
                const video = document.createElement("video");
            
                video.src = URL.createObjectURL(file);
                video.preload = "metadata";
                setAssetSrc(video.src);
                video.addEventListener("loadedmetadata", async () => {            
                    setFileData({
                        file: file,
                        assetPublicId: '',
                        caption: asset.title,
                        alt: asset.alt,
                        tags: asset.tags.split(',') as never[],
                        rotate: 0,
                        mirror: false
                    });
                });
                setEditedAssetData({
                    ...asset,
                    url: video.src,
                    type: ASSET_TYPE_VIDEO
                })
            } else {
                const img = document.createElement("img");
                img.src = URL.createObjectURL(file);
                setAssetSrc(img.src);
                img.addEventListener("load", () => {            
                    setFileData({
                        file: file,
                        assetPublicId: '',
                        caption: asset.title,
                        alt: asset.alt,
                        tags: asset.tags.split(',') as never[],
                        rotate: 0,
                        mirror: false
                    });
                });
                setEditedAssetData({
                    ...asset,
                    thumbnail: img.src,
                    type: ASSET_TYPE_IMAGE
                })
            }
        }
    }

    const handleChangeAssetData = (e: any) => {
        switch(e.target.name) {
            case "title":
                setFileData({
                    file: fileData?.file,
                    assetPublicId: '',
                    caption: e.target.value,
                    alt: fileData?.alt,
                    tags: fileData?.tags,
                    rotate: fileData?.rotate,
                    mirror: fileData?.mirror
                });
                break;
            case "alt":
                setFileData({
                    file: fileData?.file,
                    assetPublicId: '',
                    caption: fileData?.caption,
                    alt: e.target.value,
                    tags: fileData?.tags,
                    rotate: fileData?.rotate,
                    mirror: fileData?.mirror
                });
                break;
            case "tags":
                setFileData({
                    file: fileData?.file,
                    assetPublicId: '',
                    caption: fileData?.caption,
                    alt: fileData?.alt,
                    tags: e.target.value.split(',') as never[],
                    rotate: fileData?.rotate,
                    mirror: fileData?.mirror
                });
                break;
            default:
                break;
        }
    };

    const toggleMirror = () => {
        setFileData({
            file: fileData?.file,
            assetPublicId: '',
            caption: fileData?.caption,
            alt: fileData?.alt,
            tags: fileData?.tags,
            rotate: fileData?.rotate,
            mirror: !isMirrored
        });
        setIsMirrored(!isMirrored);
        setAnchorEl(null);
    };
    
    const rotateVideo = () => {
        const rotationStep = 90;
        const newRotationValue = rotateAsset(fileData?.rotate ?? asset.rotate, rotationStep);
        setFileData({
            ...fileData,
            file: fileData?.file,
            assetPublicId: '',
            caption: fileData?.caption,
            alt: fileData?.alt,
            tags: fileData?.tags,
            rotate: newRotationValue,
            mirror: fileData?.mirror
        });
        setRotationDegree(rotationDegree + rotationStep);
    };

    useEffect(()=>{
        if(rotationDegree === 360){
            setRotationDegree(0);
        }
    }, [rotationDegree]);

return (
    <Box   className={`${styles["edit-asset"]} ${(isSingleUpload || asset.percentCompleted >=1 ) ? "" : styles["setMaxHeight"] }`}>
        <Box className={styles["background"]}>
            {editedAssetData.type.includes("image") ? 
                <img 
                    src={isNew ? assetSrc : editedAssetData.thumbnail} alt={asset.title}
                    className={`${styles.thumbnail} video-element}`}
                    style={{ transform: `scale(${isMirrored ? '-1,1' : '1,1'}) rotate(${rotationDegree}deg)`}} 
                />
            :
                (
                    <video 
                        ref={videoRef}
                        className={`video-element`}
                        muted
                        width="400"
                        controls
                        preload="metadata"
                        >
                        <source src={`${isNew ? assetSrc : editedAssetData.url}#t=0.1`} type="video/mp4" />
                        <source src={`${isNew ? assetSrc : editedAssetData.url}#t=0.1`} type="video/ogg" />
                        <source src={`${isNew ? assetSrc : editedAssetData.url}#t=0.1`} type="video/webm" />
                        Your browser does not support the video tag.
                    </video>
                )   
        }

            {!isNew && <>
                <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                className={styles["circle-icon"]}
                ><BuildCircleIcon /></IconButton>
            </>}
            <InfoIcon className={styles["info-icon"]} />

            <OptionMenu
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}>
                {editedAssetData.url && editedAssetData.type === ASSET_TYPE_IMAGE && <MenuItem
                    onClick={toggleMirror}>
                    Mirror <FlipOutlinedIcon />
                </MenuItem>
                }
                {editedAssetData.url && editedAssetData.type === ASSET_TYPE_IMAGE && <MenuItem
                    onClick={() => rotateVideo()}>
                    Rotate 90° <Rotate90DegreesCcw />
                </MenuItem>
                }
                {!isNew && <MenuItem
                    className={styles.modalButton}
                    component="label"
                >
                  Upload New File <UploadOutlinedIcon />
                  <input
                    ref={fileInputRef}
                    className={styles.hiddenInput}
                    type="file"
                    onChange={(e) => handleChangeAsset(e)}
                    accept="image/*, video/*"
                  />
                </MenuItem>}
            </OptionMenu>
        </Box>
        {((asset?.percentCompleted > 0 || singleUploadProgress > 0) && (
            <Box>
                <Typography variant="body2">
                    Uploading...{" "}
                    {asset?.percentCompleted > 0 
                        ? `${asset.percentCompleted}%`
                        : isSingleUpload && singleUploadProgress > 0 
                        ? `${singleUploadProgress}%`
                        : ''}
                </Typography>
                <LinearProgress
                    variant="determinate"
                    value={
                        asset?.percentCompleted > 0 
                            ? asset.percentCompleted 
                            : isSingleUpload && singleUploadProgress > 0 
                            ? singleUploadProgress 
                            : 0
                    }
                />
            </Box>
        ))}
        <TextField
            id="outlined"
            name="title"
            label="Title"
            size="small"
            defaultValue={asset.title}
            onChange={(e) => handleChangeAssetData(e)}
        />
        <TextField
            id="outlined-multiline-static"
            name="alt"
            label="Description"
            multiline
            rows={3}
            defaultValue={asset.alt}
            onChange={(e) => handleChangeAssetData(e)}
        />
        <TextField
            name="tags"
            id="outlined"
            label="Tags"
            size="small"
            defaultValue={asset.tags}
            onChange={(e) => handleChangeAssetData(e)}
        />
        </Box>
    );
};

export default EditAsset;
