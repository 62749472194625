import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import TextIcon from '@mui/icons-material/TextFields';
import VideoIcon from '@mui/icons-material/VideoLibrary';
import ImageIcon from '@mui/icons-material/Image';
import {  Tooltip, Button } from '@mui/material';
import { ArticleComponentType } from '../../../utils/constants/constants';
import LinkIcon from '@mui/icons-material/Link';
import styles from './VITSelector.module.scss';

interface VITSelectorProps {
    onSelect: (editorType: string) => void;
    disabled?: boolean;
}

export interface VITSelectorRef {
    getData: () => { a: number; b: string; };
}

const VITSelector: React.ForwardRefRenderFunction<VITSelectorRef, VITSelectorProps> = ({ onSelect, disabled }, ref) => {
    const dataRef = useRef<{ a: number, b: string }>({ a: 10, b: 'Hello' })

    const getData = () => {
        dataRef.current.a = Math.floor(Math.random() * 100)
        return dataRef.current;
    };

    useImperativeHandle(ref, () => ({
        getData
    }));

    return (
        <div className={styles['vit-selector']}>
            <div className={styles['vit-option']}>
                <Tooltip title="Add Text">
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<TextIcon />}
                        disabled={disabled}
                        onClick={() => onSelect(ArticleComponentType.TEXT)}
                    >
                        Text
                    </Button>
                </Tooltip>                
            </div>            
            <div className={styles['vit-option']}>
                <Tooltip title="Add Task">
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<TextIcon />}
                        disabled={disabled}
                        onClick={() => onSelect(ArticleComponentType.TASK)}
                    >
                        TASK
                    </Button>
                </Tooltip>                
            </div>
            <div className={styles['vit-option']}>
                <Tooltip title="Add Survey">
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<TextIcon />}
                        disabled={disabled}
                        onClick={() => onSelect(ArticleComponentType.SURVEY)}
                    >
                        SURVEY
                    </Button>
                </Tooltip>                
            </div>
            <div className={styles['vit-option']}>
                <Tooltip title="Insert Link">
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<LinkIcon />}
                        disabled={disabled}
                        onClick={() => onSelect(ArticleComponentType.LINK)}
                    >
                        LINK
                    </Button>
                </Tooltip>                
            </div>
            <div className={styles['vit-option']}>
                <Tooltip title="Add Image">
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<ImageIcon />}
                        disabled={disabled}
                        onClick={() => onSelect(ArticleComponentType.IMAGE)}
                    >
                        Picture
                    </Button>
                </Tooltip>
            </div>
            <div className={styles['vit-option']}>
                <Tooltip title="Add Video">
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<VideoIcon />}
                        disabled={disabled}
                        onClick={() => onSelect(ArticleComponentType.VIDEO)}
                    >
                        Video
                    </Button>
                </Tooltip>
            </div>
        </div>

    );
};

export default forwardRef(VITSelector);
