import { v4 as uuidv4 } from 'uuid';
import { bytesToMB, handleGenerateThumbnail, formatDate, convertSeconds } from "../../generic";
import { ASSET_SIZE_LIMIT, ASSET_UPLOAD_INDEX } from "../../constants/constants";
import { IAssetItem } from "../../interfaces/assets";
import { findString } from '../common';
const handleCheckAssetSize = (file: File) => {
    return Number(bytesToMB(file.size)) > ASSET_SIZE_LIMIT;
};

export const handleFileProcessing = async (
    files: FileList | File[]
): Promise<any[]> => {
    const mappedFiles = Array.from(files);
    const filteredFiles = mappedFiles.filter((file) => file.type.match(/video.*/) || file.type.match(/image.*/));

    const processedFiles: any[] = [];

    for (const file of filteredFiles) {
        const [type] = file.type.split('/');
        if (type === 'video') {
            const video = document.createElement('video');
            video.src = URL.createObjectURL(file);
            video.preload = 'metadata';

            await new Promise<void>((resolve) => {
                video.addEventListener('loadedmetadata', async () => {
                    const duration = video.duration.toFixed(2);
                    const resolution = `${video.videoWidth} x ${video.videoHeight}`;
                    const width = video.videoWidth;
                    const height = video.videoHeight;

                    const thumbnail = await handleGenerateThumbnail(file);

                    const fileData = {
                        uuid: uuidv4(),
                        fileName: file.name,
                        fileSize: bytesToMB(file.size) + ' mb',
                        type: file.type,
                        url: thumbnail,
                        resolution,
                        width,
                        height,
                        duration,
                        title: file.name,
                        alt: file.name,
                        tags: "",
                        errors: handleCheckAssetSize(file),
                        file,
                    };

                    processedFiles.push(fileData);
                    resolve();
                });
            });
        } else {
            const img = document.createElement('img');
            img.src = URL.createObjectURL(file);

            await new Promise<void>((resolve) => {
                img.addEventListener('load', () => {
                    const resolution = `${img.width} x ${img.height}`;
                    const width = img.width;
                    const height = img.height;

                    const fileData = {
                        uuid: uuidv4(),
                        fileName: file.name,
                        fileSize: bytesToMB(file.size) + ' mb',
                        type: file.type,
                        url: img.src,
                        resolution,
                        width,
                        height,
                        duration: 'N/A',
                        title: file.name,
                        alt: file.name,
                        tags: "",
                        errors: handleCheckAssetSize(file),
                        file,
                    };

                    processedFiles.push(fileData);
                    resolve();
                });
            });
        }
    }

    return processedFiles;
};

export const transformAssetData = (assets: IAssetItem[]): IAssetItem[] => {
    if (!assets || assets.length === 0) return [];
    const filteredAssets = assets && assets.filter((asset: any) => {
        return !findString('undefined', asset?.eager?.thumbnail?.secure_url) && asset;
    });
    let transformed: IAssetItem[] = filteredAssets.map((asset: any) => {
      const uploadIndex =
        asset?.eager?.thumbnail?.secure_url.indexOf("/upload/") +
        ASSET_UPLOAD_INDEX;
      const newSrc =
        asset?.eager?.thumbnail?.secure_url.slice(0, uploadIndex) +
        "a_" +
        asset?.rotate +
        "/" +
        asset?.eager?.thumbnail?.secure_url.slice(uploadIndex);
      return {
        id: asset.uuid,
        assetPublicId: asset.assetPublicId,
        thumbnail: newSrc,
        title: asset.caption,
        url: asset.externalResourceUrl,
        resolution: `${asset.width}x${asset.height}`,
        duration:
          asset.resource_type === "video"
            ? convertSeconds(asset.duration)
            : "-",
        tags: asset.tags.join(","),
        date: formatDate(asset.createdAt),
        type: asset.resource_type,
        fileSize: bytesToMB(asset.fileSizeInBytes) + "mb",
        lastModifiedDate: formatDate(asset.updatedAt),
        alt: asset.alt,
        percentCompleted: asset.percentCompleted,
        rotate: asset?.rotate,
        mirror: asset?.mirror,
      };
    });
    return transformed;
  };


