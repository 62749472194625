import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from 'redux-persist';
import storage from "redux-persist/lib/storage";
import applicationReducer from "./slice/application.slice";
import editionReducer from "./slice/edition.slice";
import appReducer from "./slice/app.slice";
import authReducer from "./slice/auth.slice";
import userReducer from "./slice/users.slice";
import roleReducer from "./slice/roles.slice";
import articleReducer from "./slice/article.slice";
import assetReducer from "./slice/asset.slice";

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  user: userReducer,
  application: applicationReducer,
  edition: editionReducer,
  roles: roleReducer,
  article: articleReducer,
  asset: assetReducer,
});

type RootReducerType = ReturnType<typeof rootReducer>;

// Configuration for redux-persist
const persistConfig = {
  key: 'root',
  storage,
};

// Create a persisted reducer
const persistedReducer = persistReducer<RootReducerType>(persistConfig, rootReducer);

// Create the store
export const store = configureStore({
  reducer: persistedReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
