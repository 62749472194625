import {
  List,
  ListItemButton,
  ListItemText,
  Divider,
  ListItem,
  Checkbox,
} from '@mui/material';
import styles from './User.module.scss';
import { useState, useEffect } from 'react';
import { User } from './userTypes';
import { APPLICATION_CREATE } from '../../utils/constants/constants';

export interface Option {
  value: string;
  label: string;
}

interface UserRoleFormProps {
  options: Option[];
  modalFormValues: User;
  handleSetRoles: (Roles: string[]) => void;
}

export const UserRoleForm = ({
  options,
  modalFormValues,
  handleSetRoles,
}: UserRoleFormProps): JSX.Element => {
  const [splittedRoles, setSplittedRoles] = useState<string[]>([]);

  const handleCheckboxChange = (e: {
    target: { checked: any; name: string };
  }) => {
    setSplittedRoles((prev) => {
      if (e.target.checked) {
        return [...prev, e.target.name];
      } else {
        return prev.filter((role) => role !== e.target.name);
      }
    });
  };

  const handleConvertRolesToArray = (roles: string | string[]) => {
    const splitRoles = Array.isArray(roles)
      ? roles.map((role) => role.trim())
      : roles.split(',').map((role) => role.trim());
    setSplittedRoles(splitRoles);
  };

  useEffect(() => {
    handleConvertRolesToArray(modalFormValues.roles as string[]);
  }, []);

  useEffect(() => {
    handleSetRoles(splittedRoles);
  }, [splittedRoles]);

  return (
    <List className={styles.list}>
      <ListItemButton selected >
        <ListItemText
          className={styles.listTitle}
          primary={`Roles for ${modalFormValues.firstName} ${modalFormValues.lastName}`}
        />
      </ListItemButton>
      <Divider />
      <ListItemButton>
        <ListItemText primary={`Permissions Group`} />
      </ListItemButton>
      <Divider />
      {options.map((option) => {
        if(option.label === APPLICATION_CREATE) return;
        const labelId = `checkbox-list-label-${option.value}`;
        return (
          <ListItem key={option.value} disablePadding>
            <Checkbox
              edge='start'
              name={option.label}
              checked={splittedRoles.includes(option.label) ? true : false}
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': labelId }}
              onChange={handleCheckboxChange}
            />
            <ListItemText id={labelId} primary={`${option.label}`} />
          </ListItem>
        );
      })}
    </List>
  );
};
