
import { connect } from 'react-redux';

import { actions as authActions } from "../../redux/slice/auth.slice";
import { actions as applicationActions } from '../../redux/slice/application.slice';

import App from './App';

const { setTokens, setUserDetails } = authActions;
const { getAllApplications } = applicationActions;

export default connect(null, {
    setTokens,
    setUserDetails,
    getAllApplications
})(App);