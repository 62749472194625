import { FC } from 'react';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';

interface Props {
  moduleName?: string;
}

const Login: FC<Props> = (): JSX.Element => {
  const navigate = useNavigate()

  navigate('/home');

  return (
    <div className={styles.loginMessage}>
      Thank you for your patience. You've been successfully logged in. You'll now be redirected to home.
    </div>
  );
}
export default Login;