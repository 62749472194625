import React from "react";
import { Box, LinearProgress, Typography } from "@mui/material";
import styles from "./UploadProgress.module.scss";

interface UploadProgressProps {
    uuid: string;
    progress: number | undefined;
    failedUploads: { [uuid: string]: boolean };
    successUploads: { [uuid: string]: boolean };
    uploading: boolean;
}

export const UploadProgress: React.FC<UploadProgressProps> = ({
    uuid,
    progress,
    failedUploads,
    successUploads,
    uploading,
}) => (
    <>
        {uploading && !failedUploads[uuid] && !successUploads[uuid] && (
        <Box className={styles.uploadContainer}>
            <Typography className={styles.uploading} variant="body2">
                Uploading... {progress || 0}%
            </Typography>
            <LinearProgress className={styles.progressBar} variant="determinate" value={progress || 0} />
        </Box>
        )}
        {failedUploads[uuid] && (
        <Box className={styles.uploadContainer} display="flex" alignItems="center">
            <Typography className={styles.errorText} variant="body2">
                Failed to upload the file.
            </Typography>
        </Box>
        )}
        {successUploads[uuid] && (
        <Box className={styles.uploadContainer} display="flex" alignItems="center">
            <Typography className={styles.successText} variant="body2">
                File Uploaded Successfully
            </Typography>
        </Box>
        )}
    </>
);
