import { FC } from 'react';
import { Box, Typography } from "@mui/material";
import { FOOTER_TEXT } from "../../utils/constants/constants";
import styles from "./styles.module.scss";
interface Props {
  moduleName?: string;
}
const Footer: FC<Props>= (): JSX.Element => {
  return (
    <>
    <div className={styles.customFooter}>
    <Box>
     <Typography variant="body2" color="palette.secondary.main" align="center">
     <p data-testid="footer">{FOOTER_TEXT}</p>
     </Typography>
     </Box>
     </div>
    </>
  );
}
export default Footer;