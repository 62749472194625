import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ImageIcon from "@mui/icons-material/Image";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import AppsIcon from "@mui/icons-material/Apps";
import LanguageIcon from "@mui/icons-material/Language";

interface MenuItem {
  name: string;
  icon: JSX.Element;
  link: string;
  subMenu?: MenuItem[];
}

const LeftMenuButtons: MenuItem[] = [
  {
    name: "Home",
    icon: <HomeOutlinedIcon />,
    link: "/home",
  },
  {
    name: "User Management",
    icon: <PermIdentityIcon />,
    link: "/users",
    /* keeping this for future ref incase sub menu items are added
    subMenu:[
      {
        name: "Edit User",
        icon: <PermIdentityIcon />,
        link: "/users",
      } 
    ]
    */
  },
  {
    name: "Application Management",
    icon: <AppsIcon />,
    link: "/applications",
  },
  {
    name: "Digital Asset Management",
    icon: <ImageIcon />,
    link: "/assets",
  },
  {
    name: "API Edition Management",
    icon: <LanguageIcon />,
    link: "/api",
  },
  {
    name: "Article Management",
    icon: <InsertDriveFileOutlinedIcon />,
    link: "/articles",
  },
];

export default LeftMenuButtons;
