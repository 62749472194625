import { ChangeEvent, useState } from "react";
import {
  Button,
  Grid,
  ImageListItem,
  InputAdornment,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import styles from "./Assets.module.scss";
import { IAssetProps } from "../../utils/interfaces/assets";

export const AssetListItem = ({
  fileName,
  type,
  fileSize,
  resolution,
  duration,
  url,
  title,
  errors,
  uploadError,
  handleDeleteAsset,
  uuid,
  index,
  handleEditAssetTitle,
}: IAssetProps) => {
  const [assetTitle, setAssetTitle] = useState<string>(title ?? "");

  const handleChangeTitle = (
    e: ChangeEvent<HTMLInputElement>,
    uuid: string
  ) => {
    setAssetTitle(e.target.value);
    handleEditAssetTitle(uuid, e.target.value);
  };

  return (
    <>
      <ImageListItem
        key={url}
        className={`${styles.digitalListItem} ${
          uploadError ? styles.errorBorder : ""
        }`}
      >
        <Grid container>
          <Grid item lg={3}>
            <img
              src={url}
              alt={title}
              loading="lazy"
              className={styles.thumbnail}
            />
          </Grid>
          <Grid item lg={8}>
            <div className={styles.assetPropertiesContainer}>
              {errors ? (
                <TextField
                  className={styles.errorInput}
                  value="Videos &gt; 50mb won't be uploaded"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ErrorOutlineIcon />
                      </InputAdornment>
                    ),
                  }}
                  disabled
                  aria-readonly
                  fullWidth
                />
              ) : (
                <TextField
                  className={styles.assetTitleInput}
                  label="Title"
                  variant="outlined"
                  size="small"
                  value={assetTitle ? assetTitle : ""}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChangeTitle(e, uuid)
                  }
                />
              )}

              <ul className={styles.assetProperties}>
                <li>Type: {type}</li>
                <li>Filename: {fileName}</li>
                <li>Filesize: {fileSize}</li>
                <li>Resolution: {resolution}</li>
                <li>Duration: {duration}</li>
              </ul>
            </div>
          </Grid>
          <Grid item lg={1}>
            <Button
              className={styles.closeButton}
              onClick={() => handleDeleteAsset(uuid, url)}
            >
              <CloseIcon />
            </Button>
          </Grid>
        </Grid>
      </ImageListItem>
    </>
  );
};
