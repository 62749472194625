import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppDispatch } from '../store';
import {
  GetApplicationsResponse,
  getAllApplications as getAllApplicationsFromApi,
} from '../../utils/api/application';
import { generateReauthenticatingThunkApiAction } from './helpers';
import { Application } from "../../utils/types";
import { defaultAppLanguage } from "./article.slice";

export type ApplicationState = {
  applications: Array<Application>;
  selectedApplication: Application;
  isMenuOpen: boolean;
  language: string;
};
const initialState: ApplicationState = {
  applications: [],
  selectedApplication: {} as Application,
  isMenuOpen: false,
  language: defaultAppLanguage
};
export type SetApplicationPayload = {
  currentApplication: Application;
};
const getAllApplications = createAsyncThunk<
GetApplicationsResponse,
  null,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'application/getAllApplications',
  generateReauthenticatingThunkApiAction(async (state: RootState) => {
    const { email, accessToken } = state.auth;
    const applications = await getAllApplicationsFromApi(email, accessToken);
    return applications;
  }),
);
const application = createSlice({
  name: "application",
  initialState,
  reducers: {
    setSelectedApplication: (state: ApplicationState, action: PayloadAction<SetApplicationPayload> ) => {
      state.selectedApplication = action.payload.currentApplication;
    },
    setSelectedMenu: (state: ApplicationState, {payload}) => {
      state.isMenuOpen = payload;
    },
    setSelectedLanguage: (state: ApplicationState, {payload}) => {
      state.language = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllApplications.fulfilled, (state, { payload }) => {
      state.applications = payload;
    }); 
  },
});

export default application.reducer;

export const actions = {
  ...application.actions,
  getAllApplications,
};
