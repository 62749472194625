import React from "react";
import { Box, Typography, Modal } from "@mui/material";
import styles from "./CustomModal.module.scss";
import { ModalHeader } from "./ModalHeader";
import { ModalFooter } from "./ModalFooter";

interface CustomModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  content: string | JSX.Element;
  onSave?: () => void;
  children?: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  isPositiveButton?: boolean;
  ignoreCancelButton?: boolean;
  saveIcon?: JSX.Element;
  closeIcon?: JSX.Element;
  headerIcon?: JSX.Element;
  isProcessing?: boolean;
  isCancelDisabled?: boolean;
  isSmall?: boolean;
  hasBorder?: boolean;
  hasPadding?: boolean;
  isConfirmationButtonRight?: boolean;
  modalSize?: number;
}

const CustomModal: React.FC<CustomModalProps> = ({
  open,
  onClose,
  title,
  content,
  onSave = () => {},
  children,
  confirmText = "Save",
  cancelText = "Cancel",
  isPositiveButton = true,
  ignoreCancelButton = false,
  saveIcon,
  closeIcon,
  headerIcon,
  isProcessing = false,
  isCancelDisabled = false,
  isSmall = false,
  hasBorder = false,
  hasPadding = true,
  isConfirmationButtonRight = false,
  modalSize,
}) => {
  const handleClose = (event: {}, reason: string) => {
    if (reason !== "backdropClick") {
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className={`${styles.modalContainer} ${
          hasPadding ? "" : styles.noPadding
        }`}
        style={{ width: `${modalSize}px` }}
      >
        <ModalHeader
          headerIcon={headerIcon}
          headerText={title}
          handleClose={onClose}
          hasBorder={hasBorder}
          hasPadding={hasPadding}
          isCancelDisabled={isCancelDisabled}
        />
        <Typography
          className={styles.modalContent}
          variant="body1"
          component="div"
        >
          {content}
        </Typography>
        {children && (
          <Box className={`${styles.modalContent} ${styles.centered}`}>
            {children}
          </Box>
        )}
        <ModalFooter
          handleClose={onClose}
          handleConfirm={onSave}
          cancelText={cancelText}
          confirmText={confirmText}
          saveIcon={saveIcon}
          closeIcon={closeIcon}
          isPositiveButton={isPositiveButton}
          ignoreCancelButton={ignoreCancelButton}
          isProcessing={isProcessing}
          isCancelDisabled={isCancelDisabled}
          hasBorder={hasBorder}
          isConfirmationButtonRight={isConfirmationButtonRight}
          hasPadding={hasPadding}
        />
      </Box>
    </Modal>
  );
};

export default CustomModal;
