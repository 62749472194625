import React, { useEffect, useState, ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../utils/hooks';
import  UnAuthorized  from '../UnAuthorized/UnAuthorized'

interface AuthGuardProps {
  component: ReactNode;
}

const AuthGuard: React.FC<AuthGuardProps> = ({ component }) => {
  const [isPermit, setIsPermit] = useState(true);
  const { selectedApplication } = useAppSelector((state) => state.application);
  const userPermissions = selectedApplication?.permissions
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    checkPermission();
  }, [component]);

  const checkPermission = async (): Promise<void> => {
    if(!userPermissions){
      navigate('/') 
    }
    if(pathname ==='/users'){        
        if(!userPermissions?.some((item: string) => item.includes('Application.'))){
            setIsPermit(false); 
        }
    }
    if(pathname ==='/articles'){
        if(!userPermissions?.some((item: string) => item.includes('ArticleCollections.'))){
            setIsPermit(false);
        }
    }
  };
  return isPermit ? <>{component}</> : <UnAuthorized/>;
};

export default AuthGuard;
