import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useRef,
  forwardRef,
} from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import styles from "./ContentLink.module.scss";
import {
  IArticleComponentData,
  IAttributes,
  LangObject,
} from "../../../utils/interfaces/article";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { defaultAppLanguage } from "../../../redux/slice/article.slice";
import { isValidURL } from "../../../utils/generic/string";
interface ContentLinkDataProps {
  content: LangObject;
  attributes: IAttributes;
}
interface ContentLinkProps {
  data?: {
    value: string | null;
    content: LangObject;
    attributes: IAttributes;
  };
  onChange?: (data: IArticleComponentData) => void;
}

export interface ContentLinkRef {
  getData: () => IArticleComponentData;
}

const ContentLink: React.ForwardRefRenderFunction<
  ContentLinkRef,
  ContentLinkProps
> = ({ data, onChange }, ref) => {
  const [value, setValue] = useState<string>("");

  const [linkData, setLinkData] = useState<ContentLinkDataProps | null>(null);
  const [editData, setEditData] = useState<ContentLinkDataProps | null>(null);

  const language = useSelector(
    (state: RootState) => state.article.languageEditor
  );
  const prevLanguageRef = useRef<string>(language);
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    if (data && data.content) {
      const dataFromDb = {
        content: data.content,
        attributes: data.attributes,
      };
      setEditData(dataFromDb);
    }
  }, [data]);

  useEffect(() => {
    const currentAttributes =
      linkData && Object.keys(linkData.attributes).length
        ? linkData.attributes
        : editData?.attributes || {};
    const currentContent: LangObject =
      linkData && linkData.content && Object.keys(linkData.content).length
        ? linkData.content
        : editData?.content || {};
    const newData = {
      ...linkData,
      content: currentContent,
      attributes: currentAttributes,
    };
    setLinkData(newData);
    prevLanguageRef.current = language;
  }, [language, editData]);

  useEffect(() => {
    if (
      linkData &&
      linkData.attributes.href &&
      linkData.attributes.href[language] &&
      onChange
    ) {
      onChange(getData());
    }
  }, [linkData]);

  const getData = (): IArticleComponentData => {
    return {
      type: "LINK",
      content:
        linkData && linkData.attributes.href ? linkData.attributes.href : {},
      meta: {},
      attributes:
        linkData && linkData.attributes.href ? linkData?.attributes : {}, //linkData ? linkData.attributes : {},
    };
  };

  useImperativeHandle(ref, () => ({
    getData,
  }));

  const handleAttributesChange = (event: any) => {
    const { name, value } = event.target;
    const latt: any =
      linkData && linkData.attributes ? linkData.attributes : null;
    const lcont: LangObject =
      linkData && linkData.content ? linkData.content : {};
    const currentAttValue = latt ? latt[name] : {};
    let updatedAttributes = null;
    let updatedContent = lcont;

    if (["href", "title"].includes(name) && value !== undefined) {
      updatedAttributes = {
        ...latt,
        [name]: { ...currentAttValue, [language]: value },
      };
      if (name === "href") {
        const validationError = !isValidURL(value) ? "Invalid url" : null;
        if (validationError) {
          updatedContent = {
            ...lcont,
            [language]: value,
          };
          setError(validationError);          
          setLinkData({
            ...linkData,
            content: updatedContent,
            attributes: updatedAttributes,
          });
          return;
        }
      }
    } else {
      updatedAttributes = {
        ...latt,
        [name]: value || "",
      };
    }
    const updatedBlockData = {
      ...linkData,
      content: updatedContent,
      attributes: updatedAttributes,
    };
    setLinkData(updatedBlockData);
    setError(null);
  };

  const targetTypes = [
    { value: "_blank", label: "New Tab" },
    { value: "_self", label: "Self" },
  ];

  const relTypes = [
    { value: "noreferrer", label: "No Referrer" },
    { value: "noopener", label: "No Opener" },
  ];

  return (
    <div className={styles["content-link-container"]}>
      <div></div>
      {linkData && (
        <>
          <div className={styles["input-field"]}>
            <TextField
              label="Title"
              name="title"
              value={
                linkData.attributes.title && linkData.attributes.title[language]
                  ? linkData.attributes.title[language]
                  : ""
              }
              onChange={handleAttributesChange}
            />
          </div>
          <div>
            <TextField
              label="href"
              name="href"
              value={
                linkData.attributes.href && linkData.attributes.href[language]
                  ? linkData.attributes.href[language]
                  : ''
              }
              onChange={handleAttributesChange}
              error={Boolean(error)}
              helperText={error}
            />
          </div>
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel id="target-select-label">Target</InputLabel>
            <Select
              labelId="target-select-label"
              id="target-select"
              name="target"
              value={linkData?.attributes.target || ""}
              onChange={handleAttributesChange}
              label="Target"
            >
              {targetTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel id="rel-select-label">Rel</InputLabel>
            <Select
              labelId="rel-select-label"
              id="rel-select"
              name="rel"
              value={linkData?.attributes.rel || ""}
              onChange={handleAttributesChange}
              label="Rel"
            >
              {relTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}
    </div>
  );
};

export default forwardRef(ContentLink);
