import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  IArticleComponentData,
  LangObject,
} from "../../../utils/interfaces/article";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import styles from "./WYSIWYG.module.scss";
import { isValidJson } from "../../../utils/generic";
interface WYSIWYGProps {
  contentType: "TEXT" | "TASK" | "SURVEY";
  data: {
    value: string | null;
    content?: LangObject;
  };
  onChange?: (data: IArticleComponentData) => void;
}

export interface WYSIWYGRef {
  getData: () => IArticleComponentData;
}

const WYSIWYG: React.ForwardRefRenderFunction<WYSIWYGRef, WYSIWYGProps> = (
  { data, onChange, contentType },
  ref
) => {
  const [masterContent, setMasterContent] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");

  const language = useSelector(
    (state: RootState) => state.article.languageEditor
  );
  const prevLanguageRef = useRef<string>(language);
  const dataRef = useRef<IArticleComponentData>({
    type: contentType,
    content: {},
    meta: {},
  });

  useEffect(() => {
    if (data && data?.content && Object.keys(data?.content).length) {
      setMasterContent(JSON.stringify(data.content));
    }
  }, [data]);

  useEffect(() => {
    updateInputContent(masterContent);
  }, [masterContent]);

  useEffect(() => {
    if (prevLanguageRef.current !== language) {
      updateInputContent(masterContent);
    }
  }, [language]);

  const updateInputContent = (content?: string) => {
    const contentToUse = content || masterContent;
    const languageCode = language;
    const parsedContent = isValidJson(contentToUse)
      ? JSON.parse(contentToUse)
      : { [languageCode]: contentToUse };

    setInputValue(parsedContent[languageCode] || "");
    prevLanguageRef.current = languageCode;
  };

  const handleChange = (
    contentData: string,
    delta: any,
    source: string,
    editor: any
  ) => {
    const newContent = contentData;
    if (inputValue === newContent) return;
    const prevContent = isValidJson(masterContent)
      ? JSON.parse(masterContent)
      : { [language]: masterContent };
    const updatedContent = {
      ...prevContent,
      [language]: newContent,
    };

    setMasterContent(JSON.stringify(updatedContent));
    setInputValue(newContent);

    if (onChange) {
      const latestData = getData();
      onChange(latestData);
    }
  };

  // Common Code
  const getData = (): IArticleComponentData => {
    return {
      ...dataRef.current,
      content: isValidJson(masterContent)
        ? JSON.parse(masterContent)
        : masterContent,
        
    };
  };

  useImperativeHandle(ref, () => ({
    getData,
  }));

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }, { align: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ color: [] }, { background: [] }],
      ["link", "clean", "code-block"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "font",
    "list",
    "bullet",
    "align",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "color",
    "background",
    "link",
    "clean",
    "code-block",
  ];

  return (
    <div>
      <ReactQuill
        theme="snow"
        value={inputValue}
        onChange={handleChange}
        modules={modules}
        formats={formats}
        className={styles["quill-editor"]}
      />
    </div>
  );
};

export default forwardRef(WYSIWYG);
