import { createTheme } from '@mui/material/styles';
import { common, red } from '@mui/material/colors';

const globalTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#CC6CE7',
    },
    error: {
      main: red.A400,
    },
  },
  components: {
    MuiRadio: {
      styleOverrides: {
        root: {
          color: common.black,
          '&.Mui-checked': {
            color: common.black,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: common.black,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: common.black,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          color: common.black, // Text color
          backgroundColor: 'white', // Background color
        },
        icon: {
          color: common.black, // Dropdown icon color
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: 'white', // Background color for menu
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: common.black, // Text color
          backgroundColor: 'white', // Background color
          '&.Mui-selected': {
            backgroundColor: '#e0e0e0', // Background color of the selected item
            color: common.black, // Text color of the selected item
          },
          '&:hover': {
            backgroundColor: '#f0f0f0', // Hover background color
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: common.black, // Label color
        },
      },
    },
  },
});
// Following will be used on certain components that pocess light theme
const lightTheme = createTheme({
  palette: {
    mode: 'light'
  }
})
export { globalTheme, lightTheme };
