import React, { useEffect, useState } from "react";
import { Box, Checkbox, Grid, IconButton, MenuItem, Skeleton } from "@mui/material";
import styles from "./AssetDataRenderer.module.scss";
import commonStyles from "../../styles.module.scss";
import CustomModal from "../../components/CustomModal";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { IAssetItem, IAssetUpdateItem } from "../../utils/interfaces/assets";
import EditAsset from "./EditAsset";
import { updateAsset } from "../../utils/api/assets";
import { useAppSelector, useAppDispatch } from "../../utils/hooks";
import { ASSET_TYPE_IMAGE, SeverityType } from "../../utils/constants/constants";
import Snackbar from "../../components/Snackbar/Snackbar";
import OptionMenu from "../OptionMenu/OptionMenu";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { UPLOAD_FAILED } from "../../utils/constants/constants";
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { actions } from '../../redux/slice/app.slice';

interface AssetDataRendererProps {
  asset: IAssetItem;
  viewMode: "grid" | "list";
  isSelected: boolean;
  onSelect: (asset: any) => void;
  onDelete: (id: string) => void;
  onDuplicate: (id: any) => void;
  handleUpdateAssetList: () => void;
  getAllAssets:any;
}

const AssetDataRenderer: React.FC<AssetDataRendererProps> = ({
  asset,
  viewMode,
  isSelected,
  onSelect,
  onDelete,
  onDuplicate,
  handleUpdateAssetList,
  getAllAssets,
}) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const { selectedApplication } = useAppSelector((state) => state.application);
  const userPermissions = selectedApplication.permissions;
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [percentage, setPercentage] = useState<number>(0);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    SeverityType.Success | SeverityType.Error
  >(SeverityType.Success);
  const [fileData, setFileData] = useState<any>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const { tracking } = actions;
  const dispatch = useAppDispatch();
  const appInsights = useAppInsightsContext();
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFileData = (fileDataChange: IAssetUpdateItem) => {
    setFileData(fileDataChange);
  };

  const handleDuplicate = (id: any) => {
    onDuplicate(id);
    handleClose();
  };

  useEffect(() => {
    asset.percentCompleted = percentage;
  }, [percentage]);

  const handleEditSave = async () => {
    const promise = await new Promise((resolve, reject) => {
      updateAsset(
        fileData,
        selectedApplication.uuid,
        asset.assetPublicId,
        (progressEvent: any) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setPercentage(percentCompleted);
        }
      )
        .then(async (response: any) => {
          if (response.status === 200) {
            resolve(response);
            //dispatch getAllAssets here
            const assetList = await getAllAssets();
            dispatch(
              tracking({
                appInsights,
                name: 'debug-user',
                payload: assetList ? { type: 'getAllAssets', data: assetList } : { type: 'no getAllAssets' },
              }),
            )
            setOpenEditModal(false);
            hideSnackbar(
              false,
              "Asset Updated Successfully",
              SeverityType.Success
            );
          } else if (response.status === 403) {
            setOpenEditModal(false);
            hideSnackbar(
              false,
              "You don't have authorization to update this asset",
              SeverityType.Error
            );
          } else {
            setOpenEditModal(false);
            hideSnackbar(false, UPLOAD_FAILED, SeverityType.Error);
          }
        })
        .catch((error: any) => {
          reject(error);
        })
        .finally(() => {
          handleUpdateAssetList();
        });
    });
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleAssetClick = () => {
    setOpenEditModal(true);
    handleClose();
  };

  const handleDeleteAssetClick = (assetPublicId: any) => {
    onDelete(assetPublicId);
    handleClose();
  };

  const handleCloseSnackbar = () => {
    hideSnackbar();
  };

  const hideSnackbar = (
    hide: boolean = true,
    message: string = "",
    severity?: SeverityType
  ) => {
    setSnackbarMessage(message);
    setSnackbarSeverity((prevSeverity) => severity || prevSeverity);
    setOpenSnackbar(!hide);
  };

  const handleThumbnailLoaded = () => {
    setIsImageLoaded(true);
  }

  return (
    <>
      {viewMode === "grid" ? (
        <Box>
          <Box
            className={`${styles["grid-item"]} ${styles["item-renderer"]} ${commonStyles["standardBoxShadow"]}`}
          >
            <Box
              className={`${asset.mirror ? styles.mirrored : ""} ${
                styles["background"]
              }`}
            >
              {isImageLoaded 
                ? null 
                : <Skeleton variant="rectangular" className={styles['skeleton-thumb-placeholder']} />}
              <img
                src={asset.thumbnail}
                alt={isImageLoaded ? asset.title : ''}
                className={`${isImageLoaded ? 'show' : 'hide'}-thumbnail ${styles["thumbnail"]}` }
                onLoad={handleThumbnailLoaded}
              />
            </Box>
            <Box className={styles["item-overlay"]}>
              <Box className={styles["asset-header"]}>
                <Checkbox
                  checked={isSelected}
                  onChange={() => {
                    onSelect(asset);
                  }}
                  color="primary"
                  className={`${styles["select-checkbox"]} ${styles["MuiCheckbox-root"]}`}
                />
                <Box className={styles["grid-last-modified-date"]}>
                  {asset.lastModifiedDate}
                </Box>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                  disabled={isSelected}
                  className={styles["asset-option"]}
                >
                  <MoreVertIcon
                    className={
                      isSelected
                        ? `${styles["options-inactive"]}`
                        : `${styles["options-active"]}`
                    }
                  />
                </IconButton>
                <OptionMenu
                  anchorEl={anchorEl}
                  open={open}
                  handleClose={handleClose}
                >
                  {userPermissions?.includes("MediaLibrary.UpdateMedia") && (
                    <MenuItem onClick={handleAssetClick}>
                      Edit <BorderColorOutlinedIcon />
                    </MenuItem>
                  )}
                  {userPermissions?.includes("MediaLibrary.AddMedia") && (
                    <MenuItem onClick={() => handleDuplicate(asset.id)}>
                      Duplicate <ContentCopyOutlinedIcon />
                    </MenuItem>
                  )}
                  {userPermissions?.includes("MediaLibrary.DeleteMedia") && (
                    <MenuItem
                      onClick={() =>
                        handleDeleteAssetClick(asset.assetPublicId)
                      }
                    >
                      Delete <DeleteOutlineOutlinedIcon />
                    </MenuItem>
                  )}
                </OptionMenu>
              </Box>
              <Box className={styles["asset-info"]}>
                <h3>{asset.title}</h3>
                <Box>
                  <p>{asset.url.substring(asset.url.lastIndexOf(".") + 1)}</p>
                  <p>{asset.resolution}</p>
                  <p>{asset.duration}</p>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <div
          className={`${styles["list-item"]} ${styles["list-item-renderer"]} ${commonStyles["standardBoxShadow"]}`}
        >
          <Grid container className={styles["list-asset-info"]}>
            <Grid item xs={0.5} className={styles["content-center"]}>
              <Checkbox
                checked={isSelected}
                onChange={() => {
                  onSelect(asset);
                }}
                color="primary"
                className={`${styles["select-checkbox"]} ${styles["MuiCheckbox-root"]}`}
              />
            </Grid>
            <Grid item xs={1} className={styles["padding-thick"]}>
              {asset.type === ASSET_TYPE_IMAGE ? (
                <img
                  src={asset.thumbnail}
                  alt={asset.title}
                  className={styles["list-thumbnail"]}
                />
              ) : (
                <video
                  poster={asset.thumbnail}
                  className={styles["list-thumbnail"]}
                  preload="metadata"
                >
                  <source src={`${asset.url}#t=0.1`} type="video/mp4" />
                  <source src={`${asset.url}#t=0.1`} type="video/ogg" />
                  <source src={`${asset.url}#t=0.1`} type="video/webm" />
                  <p>
                    Your browser doesn't support HTML video.
                  </p>
                </video>
              )}
            </Grid>
            <Grid item xs={1} className={styles["list-asset-details"]}>
              <Box className={styles["list-asset-title"]}>Title:</Box>
              <Box className={styles["list-asset-description"]}>
                {asset.title}
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box className={styles["list-asset-title"]}>Type:</Box>
              <Box className={styles["list-asset-description"]}>
                {asset.type}
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box className={styles["list-asset-title"]}>FileSize:</Box>
              <Box className={styles["list-asset-description"]}>
                {asset.fileSize}
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box className={styles["list-asset-title"]}>Resolution:</Box>
              <Box className={styles["list-asset-description"]}>
                {asset.resolution}
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box className={styles["list-asset-title"]}>Duration:</Box>
              <Box className={styles["list-asset-description"]}>
                {asset.duration}
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box className={styles["list-asset-title"]}>Added Date:</Box>
              <Box className={styles["list-asset-description"]}>
                {asset.date}
              </Box>
            </Grid>
            <Grid item xs={1.5}>
              <Box className={styles["list-asset-title"]}>Last Modified:</Box>
              <Box className={styles["list-asset-description"]}>
                {asset.lastModifiedDate}
              </Box>
            </Grid>
            <Grid item xs={2.5}>
              <Box className={styles["list-asset-title"]}>Tags:</Box>
              <Box className={styles["list-asset-description"]}>
                {asset.tags}
              </Box>
            </Grid>

            <Grid
              item
              xs={0.5}
              className={`${styles["list-row-item"]} ${styles["content-center"]}`}
            >
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                disabled={isSelected}
                className={styles["cursor-pointer"]}
              >
                <MoreVertIcon
                  className={
                    isSelected
                      ? `${styles["options-inactive"]}`
                      : `${styles["options-active"]}`
                  }
                />
              </IconButton>
              <OptionMenu
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
              >
                {userPermissions?.includes("MediaLibrary.UpdateMedia") && (
                  <MenuItem onClick={handleAssetClick}>
                    Edit <BorderColorOutlinedIcon />
                  </MenuItem>
                )}
                <MenuItem onClick={() => onDuplicate(asset.id)}>
                  Duplicate <ContentCopyOutlinedIcon />
                </MenuItem>
                <MenuItem
                  onClick={() => handleDeleteAssetClick(asset.assetPublicId)}
                >
                  Delete <DeleteOutlineOutlinedIcon />
                </MenuItem>
              </OptionMenu>
            </Grid>
          </Grid>
        </div>
      )}

      <CustomModal
        open={openEditModal}
        onClose={handleCloseEditModal}
        onSave={handleEditSave}
        title="Edit Asset"
        content=""
        confirmText="Confirm"
        headerIcon={<BorderColorIcon />}
        saveIcon={<DoneIcon />}
        closeIcon={<CloseIcon />}
      >
        <EditAsset asset={asset} onFileDataChange={handleFileData}></EditAsset>
      </CustomModal>
      <Snackbar
        open={openSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleCloseSnackbar}
      />
    </>
  );
};

export default AssetDataRenderer;
