import * as React from "react";
import {
  Toolbar,
  Typography,
  TextField,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import SearchIcon from '@mui/icons-material/Search';
import styles from "./Searchbar.module.scss";

interface EnhancedTableToolbarProps {
  numSelected?: number;
  title?: string;
  onDeleteClick?: (event: any) => void;
  searchQuery: string;
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
}

export function SearchBar(props: EnhancedTableToolbarProps) {
  const { numSelected, title, onDeleteClick, searchQuery, onSearchChange, placeholder } =
    props;
  return (
    <Toolbar className={styles.toolbar}>
      {numSelected && numSelected > 0 ? (
        <Typography
          className={styles.typography}
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={styles.typography}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
      )}
      {numSelected && numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={onDeleteClick}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <>
        <TextField
          variant="outlined"
          placeholder={placeholder}
          size="small"
          value={searchQuery}
          className={styles.searchBar}
          onChange={onSearchChange}
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
        />
        </>
      )}
    </Toolbar>
  );
}
