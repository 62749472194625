import axiosInstance from "../axiosInstance";
import logger from "../../utils/logger";
import { IAssetItem } from "../interfaces/assets";
import {
  transformAssetData,
} from "../../utils/helpers/assets/assetHelpers";
interface FileData {
  file: File;
  fileName: string;
  title: string;
  tags: string;
}

interface AssetFilterBy {
  tags?: string;
  createdAt?: string;
  updatedAt?: string;
}
interface FetchBodyProps {
  searchTerms?: string[];
  requestedLimit?: number;
  continuationToken?: string;
  filterValues?: AssetFilterBy[];
  sortOrderBy?: string;
  sortOrderByValue?: string;
  sortMode?: string;
}

interface MultipleDeleteBodyProps {
  assetPublicIds?: string[];
}

interface MultipleAssetsBodyProps {
  assetPublicIds: string[];
  newTags: string[];
}

export const updateAsset = async (
  fileData: any,
  app_uuid: string,
  asset_public_id: string,
  onUploadProgress: (progressEvent: any) => void
): Promise<any> => {
  const formData = new FormData();
  if (fileData.file !== undefined) {
    formData.append("file", fileData.file);
  }
  formData.append("assetPublicId", asset_public_id);
  formData.append("caption", fileData.caption);
  formData.append("alt", fileData.alt);
  formData.append("tags", fileData.tags);
  formData.append("rotate", fileData.rotate);
  formData.append("mirror", fileData.mirror);
  try {
    const response = await axiosInstance.post(
      `/asset/update/${app_uuid}`,
      formData,
      {
        onUploadProgress,
      }
    );

    if (response.status === 200) {
      return response;
    } else {
      throw new Error(`Failed to update asset: ${response.statusText}`);
    }
  } catch (error: any) {
    throw new Error(`Error updating asset: ${error.message}`);
  }
};

export const uploadAsset = async (
  fileData: FileData,
  app_uuid: string,
  onUploadProgress: (progressEvent: any) => void
): Promise<any> => {
  const formData = new FormData();
  formData.append("file", fileData.file);
  formData.append("caption", fileData.title);
  formData.append("alt", fileData.fileName);
  formData.append("tags", fileData.tags);
  try {
    const response = await axiosInstance.post(
      `/asset/upload/${app_uuid}`,
      formData,
      {
        onUploadProgress,
      }
    );

    if (response.status === 200) {
      return response;
    } else {
      throw new Error(`Failed to upload asset: ${response.statusText}`);
    }
  } catch (error: any) {
    throw new Error(`Error uploading asset: ${error.message}`);
  }
};
export type GetAssetsResponse = Array<IAssetItem>;

export const fetchAllAssets = async (app_uuid: string,
  body?: FetchBodyProps): Promise<any> => {  
    try{  
      logger('info', 'ASSETS_GET_REQUEST');
      const res = await axiosInstance.post(`/asset/${app_uuid}/assetList`, {
        ...body,
      }); 
      return transformAssetData([...res.data.data.assets]);
    } catch (error: any) {     
      logger('error', `ASSETS_GET_FAILURE ${error.response.data.message}`); 
      throw new Error(`Error while fetching asset: ${error.response.data.message}`);
    }      
};

export const fetchAssets = async (
  app_uuid: string,
  body?: FetchBodyProps
): Promise<any> => {
  try {
    const response = await axiosInstance.post(`/asset/${app_uuid}/assetList`, {
      ...body,
    });
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};

export const duplicateAsset = async (uuid: string): Promise<any> => {
  try {
    const response = await axiosInstance.get(`/asset/duplicate/${uuid}`);

    return response;
  } catch (error: any) {
    let errorMessage = "An error occurred while duplicating assets.";
    if (error.response) {
      errorMessage = `Error duplicating assets: ${error.response.data}`;
    } else if (error.request) {
      errorMessage =
        "No response received from server. Please check your connection.";
    } else {
      errorMessage = `Error duplicating assets: ${error.message}`;
    }
    throw new Error(errorMessage);
  }
};

export const deleteMultipleAssets = async (
  app_uuid: string,
  body?: MultipleDeleteBodyProps
): Promise<any> => {
  try {
    logger("info", "MULTIPLE_ASSETS_DELETE_REQUEST");
    logger("info", `appUUID: ${app_uuid}, assetList: ${body}`);

    const response = await axiosInstance.post(
      `/asset/deleteMultiple/${app_uuid}`,
      {
        ...body,
      }
    );

    return response;
  } catch (error: any) {
    let errorMessage = "An error occurred while deleting assets.";
    if (error.response) {
      errorMessage = `Error deleting assets: ${error.response.data}`;
    } else if (error.request) {
      errorMessage =
        "No response received from server. Please check your connection.";
    } else {
      errorMessage = `Error deleting assets: ${error.message}`;
    }
    throw new Error(errorMessage);
  }
};

export const deleteSingleAsset = async (
  app_uuid: string,
  selectedPublicUUID: string
): Promise<any> => {
  try {
    logger("info", "SINGLE_ASSETS_DELETE_REQUEST");
    logger("info", `appUUID: ${app_uuid}, assetId: ${selectedPublicUUID}`);
    const idParts = selectedPublicUUID.split("/");
    const assetId = idParts[idParts.length - 1];
    const response = await axiosInstance.delete(
      `/asset/delete/${app_uuid}/${assetId}`
    );

    return response;
  } catch (error: any) {
    let errorMessage = "An error occurred while deleting assets.";
    if (error.response) {
      errorMessage = `Error deleting assets: ${error.response.data}`;
    } else if (error.request) {
      errorMessage =
        "No response received from server. Please check your connection.";
    } else {
      errorMessage = `Error deleting assets: ${error.message}`;
    }
    throw new Error(errorMessage);
  }
};

export const updateAssetTags = async (
  app_uuid: string,
  body?: MultipleAssetsBodyProps
): Promise<any> => {
  try {
    logger("info", "ASSETS_TAGS_UPDATION");
    logger("info", `appUUID: ${app_uuid}, assetList: ${body}`);
    const response = await axiosInstance.post(`/asset/updateTags/${app_uuid}`, {
      ...body,
    });
    return response;
  } catch (error: any) {
    let errorMessage = "An error occurred while updating asset tags";
    if (error.response) {
      errorMessage = `Error updating assets tags: ${error.response.data}`;
    } else if (error.request) {
      errorMessage =
        "No response received from server. Please check your connection.";
    } else {
      errorMessage = `Error updating assets tags: ${error.message}`;
    }
    throw new Error(errorMessage);
  }
};

// API method to fetch suggested tags from backend
export const fetchSuggestedTagsAPI = async (
  applicationUUID: string,
  query: string
): Promise<string[]> => {
  try {
    const response = await axiosInstance.get(
      `/asset/fetchTags/${applicationUUID}`,
      {
        params: { query },
      }
    );

    if (response.data.success) {
      return response.data.tags;
    } else {
      throw new Error("Failed to fetch tags");
    }
  } catch (error: any) {
    let errorMessage = "An error occurred while fetching suggested tags";
    if (error.response) {
      errorMessage = `Error fetching suggested tags: ${error.response.data}`;
    } else if (error.request) {
      errorMessage =
        "No response received from server. Please check your connection.";
    } else {
      errorMessage = `Error fetching suggested tags: ${error.message}`;
    }
    console.error(errorMessage);
    throw new Error(errorMessage);
  }
};
