export const toTitleCase = (text: string): string => {
    return text
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

export const isValidJson = (jsonString: string): boolean => {
    try {
        JSON.parse(jsonString);
        return true;
    } catch {
        return false;
    }
};
export const isValidURL = (url: string): boolean => {
	try {
		new URL(url);
		return true;
	} catch {
		return false;
	}
};

