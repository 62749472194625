import { FC } from "react";
import styles from './UnAuthorized.module.scss';
import { SUPPORT_TEAM_EMAIL } from "../../utils/constants/constants";

interface Props {
  moduleName?: string;
}

const UnAuthorized: FC<Props> = (): JSX.Element => {

  return (
    <>
      <div className={styles.errMessage}><p>It looks like you have not been granted permissions to view this page. <br></br> Please contact one of the system administrators via {SUPPORT_TEAM_EMAIL} .</p></div>
    </>
  );
};

export default UnAuthorized;
