import Button from '@mui/material/Button';

interface BaseButtonProps {
  variant?: 'outlined' | 'text' | 'contained' | string;
  color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | string;
  size?: "medium" | "small" | "large" | string;
  text?: string;
  className?: string;
  handleClick?: () => void;
  icon: JSX.Element;
}

export const BaseButton = ({
  variant = 'outlined',
  color = '#fff',
  size = 'medium',
  text,
  className,
  handleClick,
  icon,
}: BaseButtonProps | any) => {
  return (
    <Button
      className={className}
      variant={variant}
      color={color}
      size={size}
      onClick={handleClick}
      endIcon={icon}
      >
      {text}
    </Button>
  );
}