import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from '../store';
export const tracking = createAsyncThunk(
  "app/tracking",
  async (trackingObj: any, thunkAPI) => {
    const { auth } = thunkAPI.getState() as RootState;
    const { appInsights, name, payload } = trackingObj;

    try {
      const properties = {
        ...payload,
        user: {
          email: auth.email,
          firstName: auth.firstName,
          lastName: auth.lastName,
        },
      };

      appInsights.trackEvent({
        name,
        properties,
      });

      console.log(`Track Event: ${name}`, properties);
    } catch (e) {
      console.log(e);
    }
  }
);

const app = createSlice({
  name: "app",
  initialState: {
    isNativeWebView:
      window.location.search.includes("isNativeWebView=true") ||
      localStorage.getItem("isNativeWebView") === "true",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(tracking.fulfilled, (state, { payload }) => { });
  },
});

export default app.reducer;

export const actions = {
  ...app.actions,
  tracking,
};
