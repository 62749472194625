import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ArticleState {
    languageEditor: string;
    languagePreview: string;
}
export const defaultAppLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE || 'en-GB';
const initialState: ArticleState = {
    languageEditor: defaultAppLanguage,
    languagePreview: defaultAppLanguage,
};

const article = createSlice({
    name: 'articleLanguage',
    initialState,
    reducers: {
        setReduxStateLanguageEditor(state, action: PayloadAction<string>) {
            state.languageEditor = action.payload;
        },
        setReduxStateLanguagePreview(state, action: PayloadAction<string>) {
            state.languagePreview = action.payload;
        },
    },
});

export const { setReduxStateLanguageEditor, setReduxStateLanguagePreview } = article.actions;

export default article.reducer;
