import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Edition } from "../../utils/types";

export type EditionState = {
  editions: Array<Edition>;
  selectedEdition: Edition;
};
const initialState: EditionState = {
  editions: [],
  selectedEdition: {} as Edition,
};
export type SetEditionPayload = {
  currentEdition: Edition;
};

const edition = createSlice({
  name: "edition",
  initialState,
  reducers: {    
      setSelectedEdition: (
        state: EditionState,
        action: PayloadAction<SetEditionPayload>
      ) => {
      state.selectedEdition = action.payload.currentEdition;
    }, 
  },
});

export default edition.reducer;

export const actions = {
  ...edition.actions
};
