import { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { actions } from "../../redux/slice/auth.slice";
import { useDispatch } from "react-redux"

import styles from './styles.module.scss';

import { BaseButton } from "../../utils/ui/buttons";
import LoginSharp from "@mui/icons-material/LoginSharp";


import { useNavigate } from "react-router-dom";


const LogoutPage: FC = (): JSX.Element => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate("/login");
  };

  dispatch(actions.logout())

  return (
    <div className={styles.logoutMessage}>
      <h2>Bye bye!</h2>
      <p>You have been successfully logged out.<br/>
        Want to come back?
      </p>
      <BaseButton
            color="primary"
            variant="contained"
            text="Log in again"
            icon={<LoginSharp />}
            handleClick={handleSubmit}
          ></BaseButton>
    </div>
  );
}
export default LogoutPage;