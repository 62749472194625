import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const axiosInstance: AxiosInstance = axios.create({
        baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use(
    (config: AxiosRequestConfig): any => {

        if (localStorage.getItem("access_token")) {
            config.headers = config.headers || {}; // Ensure headers is an object
            config.headers['x-build-id'] = `${process.env.REACT_APP_BUILD_ID}`;
            config.headers['Authorization'] = `Bearer ${localStorage.getItem("access_token")}`;
            config.headers['x-app-uuid'] = localStorage.getItem('selectedAppUUID');
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;