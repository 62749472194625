import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import { Autocomplete, TextField, Switch } from "@mui/material";
import styles from "./ImageSelector.module.scss";
import {
  IArticleComponentData,
  IAttributes,
  LangObject,
  AssetSelector,
} from "../../../utils/interfaces/article";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { defaultAppLanguage } from "../../../redux/slice/article.slice";
import { IAssetItem } from "../../../utils/interfaces/assets";
import { useAppSelector } from "../../../utils/hooks";
interface Image {
  content?: LangObject;
  attributes: IAttributes;
}
interface Data {
  type?: string;
  value: string | null;
  content?: LangObject;
  attributes?: IAttributes;
}

interface ImageSelectorProps {
  data: Data;
  isIcon?: boolean;
  onChange?: (data: IArticleComponentData) => void;
  disabled?: boolean;
}

export interface ImageSelectorRef {
  getData: () => IArticleComponentData;
}

const ImageSelector: React.ForwardRefRenderFunction<
  ImageSelectorRef,
  ImageSelectorProps
> = ({ data, onChange, isIcon, disabled }, ref) => {
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);
  const [editData, setEditData] = useState<Image | null>(null);

  const language = useSelector(
    (state: RootState) => state.article.languageEditor
  );
  const prevLanguageRef = useRef<string>(language);

  const [images, setImages] = useState<AssetSelector[]>([]);
  const [autoCompleteValue, setAutocompleteValue] =
    useState<AssetSelector | null>(null);

  const { assets: allAssets } = useAppSelector(
    (state: RootState) => state.asset
  );

  useEffect(() => {
    if (!allAssets.length) return;
    const allImages = allAssets.filter(
      (asset: IAssetItem): boolean => asset.type === "image"
    );
    const generateImageData = () => {
      let imgs: AssetSelector[] = [];
      allImages.forEach((img: IAssetItem) => {
        let item: AssetSelector = {
          attributes: {
            src: img.url,
            alt: img.alt,
            title: img.title,
            lazyLoading: true,
          },
        };
        imgs.push(item);
        if (selectedImage && Object.keys(selectedImage).length) {
          if (
            selectedImage &&
            selectedImage.attributes &&
            selectedImage.attributes.src !== undefined &&
            selectedImage.attributes.src[language] === img.url
          )
            setAutocompleteValue(item);
        }
      });

      setImages(imgs);
    };

    generateImageData();
  }, [allAssets, selectedImage]);

  useEffect(() => {
    if (data && (data.content || data.attributes)) {
      const dataFromDb: any = {
        content: data.content,
        attributes: data.attributes,
      };
      setEditData(dataFromDb);
    }
  }, [data]);

  useEffect(() => {
    const currentAttributes =
    selectedImage && Object.keys(selectedImage.attributes).length
      ? selectedImage.attributes
      : editData?.attributes || {};
    const currentContent =
    selectedImage && selectedImage.content && Object.keys(selectedImage.content).length
        ? selectedImage.content
        : editData?.content;
    const newData = {
      ...selectedImage,
      content: currentContent,
      attributes: currentAttributes,
    };
    setAutocompleteValue(null);
    setSelectedImage(newData);
    prevLanguageRef.current = language;
  }, [language, editData]);

  useEffect(() => {
    if (selectedImage && selectedImage.attributes.src && onChange) {
      onChange(getData());
    }
  }, [selectedImage]);

  const getData = (): IArticleComponentData => {
    return {
      type: "IMAGE",
      content: selectedImage && selectedImage.attributes.src ? selectedImage.attributes.src : {},//selectedImage ? selectedImage.attributes.src : {},
      meta: {},
      attributes:
        selectedImage && selectedImage.attributes.src
          ? selectedImage?.attributes
          : {},
    };
  };

  useImperativeHandle(ref, () => ({
    getData,
  }));

  const onChangeAsset = (
    event: React.ChangeEvent<{}>,
    value: AssetSelector | null
  ) => {
    if (value && Object.keys(value).length) {
      let currentAttributes1: any = value.attributes;
      const selectedAttributes = Object.keys(currentAttributes1).reduce(
        (acc: any, key: string) => {
          const val: any = currentAttributes1 ? currentAttributes1[key] : "";
          const att: any = selectedImage ? selectedImage.attributes : null;
          acc[key] = ["src", "alt", "title"].includes(key)
            ? {
                ...(att ? att[key] : {}),
                [language]: val,
              }
            : val || "";
          return acc;
        },
        {}
      );
      const updatedMedia: Image = {
        ...selectedImage,
        content: selectedAttributes.src,
        attributes: selectedAttributes,
      };
      setSelectedImage(updatedMedia);
      setAutocompleteValue(null);
    }
  };

  const handleAttributesChange = (name: string, value?: string | boolean) => {
    const latt: any =
    selectedImage && selectedImage.attributes ? selectedImage.attributes : null;
    const lcont: any = selectedImage && selectedImage.content ? selectedImage.content : null;
    const currentAttValue = latt ? latt[name] : {};
    let updatedAttributes = {};
    let updatedContent = lcont;

    if (["alt", "title"].includes(name) && value !== undefined) {
      updatedAttributes = {
        ...latt,
        [name]: { ...currentAttValue, [language]: value },
      };
    } else {
      updatedAttributes = {
        ...latt,
        [name]: value || "",
      };
    }
    const updatedBlockData = {
      ...selectedImage,
      content: updatedContent,
      attributes: updatedAttributes,
    };
    setSelectedImage(updatedBlockData);

  };

  return (
    <div className={styles["image-selector-container"]}>
      <div className={styles["selected-image"]}>
        {selectedImage?.attributes.src && (
          <img
            src={
              selectedImage.attributes.src
                ? selectedImage.attributes.src[language]
                : ""
            }
            alt={
              selectedImage.attributes.alt
                ? selectedImage.attributes.alt[language]
                : ""
            }
          />
        )}
      </div>
      <div className={styles["image-picker"]}>
        <div>
          <Autocomplete
            options={images}
            getOptionLabel={(option) =>
              option.attributes.title ? option.attributes.title : ""
            }
            value={autoCompleteValue}
            onChange={onChangeAsset}
            renderOption={(props, option) => (
              <li {...props}>
                <img
                  className={styles["thumbnail-image"]}
                  src={option.attributes.src ? option.attributes.src : ""}
                  alt={option.attributes.alt ? option.attributes.alt : ""}
                />
                {option.attributes.title ? option.attributes.title : ""}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={isIcon ? "Choose icon" : "Choose image"}
              />
            )}
            disabled={disabled}
            noOptionsText="No image found"
          />
        </div>
        <div>
          <TextField
            label="Title"
            value={
              selectedImage &&
              selectedImage.attributes &&
              selectedImage.attributes.title &&
              selectedImage.attributes.title[language]
                ? selectedImage.attributes.title[language]
                : ""
            }
            onChange={(e) => handleAttributesChange("title", e.target.value)}
          />
        </div>
        <div>
          <TextField
            label="URL"
            value={
              selectedImage &&
              selectedImage.attributes &&
              selectedImage.attributes &&
              selectedImage.attributes.src !== undefined &&
              selectedImage.attributes.src[language]
                ? selectedImage.attributes.src[language]
                : ""
            }
            InputProps={{ readOnly: true }}
            disabled={disabled}
          />
        </div>
        {!isIcon && (
          <>
            <div>
              <TextField
                label="Alt-Text"
                value={
                  selectedImage &&
                  selectedImage.attributes.alt &&
                  selectedImage.attributes.alt[language]
                    ? selectedImage.attributes.alt[language]
                    : ""
                }
                onChange={(e) => handleAttributesChange("alt", e.target.value)}
                disabled={disabled}
              />
            </div>
            <>
              <div>
                <label>Lazy Loading:</label>
                <Switch
                  checked={
                    selectedImage &&
                    selectedImage?.attributes.lazyLoading !== undefined
                      ? selectedImage.attributes.lazyLoading
                      : false
                  }
                  onChange={(e) =>
                    handleAttributesChange("lazyLoading", e.target.checked)
                  }
                  inputProps={{ "aria-label": "toggle button" }}
                  aria-disabled={disabled}
                />
              </div>
            </>
          </>
        )}
      </div>
    </div>
  );
};

export default forwardRef(ImageSelector);
