import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useRef,
  forwardRef,
} from "react";
import { Autocomplete, TextField, Switch } from "@mui/material";
import styles from "./VideoSelector.module.scss";
import {
  IArticleComponentData,
  IAttributes,
  LangObject,
  AssetSelector,
} from "../../../utils/interfaces/article";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { defaultAppLanguage } from "../../../redux/slice/article.slice";
import { IAssetItem } from "../../../utils/interfaces/assets";
import { useAppSelector } from "../../../utils/hooks";

interface Video {
  content?: LangObject;
  attributes: IAttributes;
  thumbnail?: string;
}
interface Data {
  type?: string;
  value: string | null;
  content?: LangObject;
  attributes?: IAttributes;
}

interface VideoSelectorProps {
  data: Data;
  onChange?: (data: IArticleComponentData) => void;
}

export interface VideoSelectorRef {
  getData: () => IArticleComponentData;
}

const VideoSelector: React.ForwardRefRenderFunction<
  VideoSelectorRef,
  VideoSelectorProps
> = ({ data, onChange }, ref) => {
  const [editData, setEditData] = useState<Video | null>(null);
  const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);
  const language = useSelector(
    (state: RootState) => state.article.languageEditor
  );
  const prevLanguageRef = useRef<string>(language);
  const [videos, setVideos] = useState<AssetSelector[]>([]);
  const [autoCompleteValue, setAutocompleteValue] =
    useState<AssetSelector | null>(null);

  const { assets: allAssets } = useAppSelector(
    (state: RootState) => state.asset
  );

  useEffect(() => {
    if (!allAssets.length) return;
    const allVideos = allAssets.filter(
      (asset: IAssetItem): boolean => asset.type === "video"
    );
    const generateVideoData = () => {
      let videos: AssetSelector[] = [];
      allVideos.forEach((vid: IAssetItem) => {
        let item: AssetSelector = {
          attributes: {
            src: vid.url,
            title: vid.title,
            controls: false,
            autoplay: false,
            loop: false,
            muted: true,
            poster: "",
            thumbnail: vid.thumbnail,
          },
        };
        videos.push(item);
        if (selectedVideo && Object.keys(selectedVideo).length) {
          if (
            selectedVideo &&
            selectedVideo.content &&
            selectedVideo.content[language] === vid.url
          )
            setAutocompleteValue(item);
        }
      });
      setVideos(videos);
    };

    generateVideoData();
  }, [allAssets, selectedVideo]);

  useEffect(() => {
    if (data && data.content) {
      const dataFromDb:any = {
        content: data.content,
        attributes: data.attributes
      };
      setEditData(dataFromDb);
    }
  }, [data]);

  useEffect(() => {
    const currentAttributes =
    selectedVideo && Object.keys(selectedVideo.attributes).length
      ? selectedVideo.attributes
      : editData?.attributes || {};
    const currentContent =
    selectedVideo && selectedVideo.content && Object.keys(selectedVideo.content).length
        ? selectedVideo.content
        : editData?.content;
    const newData = {
      ...selectedVideo,
      content: currentContent,
      attributes: currentAttributes,
    };
        setAutocompleteValue(null);
    setSelectedVideo(newData);
    prevLanguageRef.current = language;
  }, [language, editData]);

  useEffect(() => {
    if (selectedVideo && selectedVideo.attributes.src && onChange) {
      onChange(getData());
    }
  }, [selectedVideo]);

  const getData = (): IArticleComponentData => {
    return {
      type: "VIDEO",
      content: selectedVideo && selectedVideo.attributes.src ? selectedVideo.attributes.src : {},
      meta: {},
      attributes:
        selectedVideo && selectedVideo.attributes.src
          ? selectedVideo?.attributes
          : {},
    };
  };

  useImperativeHandle(ref, () => ({
    getData,
  }));

  const onChangeAsset = (
    event: React.ChangeEvent<{}>,
    value: AssetSelector | null
  ) => {
    if (value && Object.keys(value).length) {
      let currentAttributes1: any = value.attributes;
      const selectedAttributes = Object.keys(currentAttributes1).reduce(
        (acc: any, key: string) => {
          const val = currentAttributes1 ? currentAttributes1[key] : "";
          const vatt: any =
            selectedVideo && selectedVideo.attributes
              ? selectedVideo.attributes
              : null;
          acc[key] =
            currentAttributes1 &&
            typeof currentAttributes1[key] === "string" &&
            ["src", "poster", "title"].includes(key)
              ? {
                  ...(selectedVideo && selectedVideo.attributes
                    ? vatt[key]
                    : {}),
                  [language]: val,
                }
              : val || false;
          return acc;
        },
        {}
      );

      const updatedMedia: Video = {
        ...selectedVideo,
        content: selectedAttributes.src,
        attributes: selectedAttributes,
      };
      setSelectedVideo(updatedMedia);
      setAutocompleteValue(null);
    }
  };
  const handleAttributesChange = (name: string, value?: boolean | string) => {
    const latt: any =
    selectedVideo && selectedVideo.attributes ? selectedVideo.attributes : null;
    const lcont: any = selectedVideo && selectedVideo.content ? selectedVideo.content : null;
    const currentAttValue = latt ? latt[name] : {};
    let updatedAttributes = {};
    let updatedContent = lcont;

    if (["poster", "title"].includes(name) && value !== undefined) {
      updatedAttributes = {
        ...latt,
        [name]: { ...currentAttValue, [language]: value },
      };
    } else {
      updatedAttributes = {
        ...latt,
        [name]: value || "",
      };
    }
    const updatedBlockData = {
      ...selectedVideo,
      content: updatedContent,
      attributes: updatedAttributes,
    };

    setSelectedVideo(updatedBlockData);
  };

  return (
    <div className={styles["video-selector-container"]}>
      <div className={styles["selected-video"]}>
        {selectedVideo?.attributes.src && selectedVideo.attributes.src[language] && (
          <video
            controls
            src={
              selectedVideo.attributes.src && selectedVideo.attributes.src[language]
                ? selectedVideo.attributes.src[language]
                : ""
            }
            width="260"
            height="260"
          >
            Your browser does not support the video tag.
          </video>
        )}

      </div>
      <div className={styles["video-picker"]}>
        <div>
          <Autocomplete
            options={videos}
            getOptionLabel={(option) =>
              option.attributes.title ? option.attributes.title : ""
            }
            value={autoCompleteValue}
            onChange={onChangeAsset}
            renderOption={(props, option) => (
              <li {...props}>
                <img
                  className={styles["thumbnail-image"]}
                  src={
                    option.attributes.thumbnail
                      ? option.attributes.thumbnail
                      : ""
                  }
                  alt={option.attributes.alt ? option.attributes.alt : ""}
                />
                {option.attributes.title ? option.attributes.title : ""}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Choose video" />
            )}
            noOptionsText="No video found"
          />
        </div>
        <div>
          <TextField
            label="Title"
            value={
              selectedVideo &&
              selectedVideo.attributes &&
              selectedVideo.attributes.title &&
              selectedVideo.attributes.title[language]
                ? selectedVideo.attributes.title[language]
                : ""
            }
            onChange={(e) => handleAttributesChange("title", e.target.value)}
            // InputProps={{ readOnly: true }}
          />
        </div>
        <div>
          <TextField
            label="URL"
            value={
              selectedVideo &&
              selectedVideo.attributes &&
              selectedVideo.attributes.src &&
              selectedVideo.attributes.src[language]
                ? selectedVideo.attributes.src[language]
                : ""
            }
            InputProps={{ readOnly: true }}
          />
        </div>
        {selectedVideo && (
          <>
            <div>
              <label>Controls:</label>
              <Switch
                checked={
                  selectedVideo &&
                  selectedVideo?.attributes.controls !== undefined
                    ? selectedVideo.attributes.controls
                    : false
                }
                onChange={(e) =>
                  handleAttributesChange("controls", e.target.checked)
                }
                inputProps={{ "aria-label": "toggle button" }}
              />
            </div>
            <div>
              <label>Autoplay:</label>
              <Switch
                checked={
                  selectedVideo &&
                  selectedVideo?.attributes.autoplay !== undefined
                    ? selectedVideo.attributes.autoplay
                    : false
                }
                onChange={(e) =>
                  handleAttributesChange("autoplay", e.target.checked)
                }
                inputProps={{ "aria-label": "toggle button" }}
              />
            </div>
            <div>
              <label>Loop:</label>
              <Switch
                checked={
                  selectedVideo && selectedVideo?.attributes.loop !== undefined
                    ? selectedVideo.attributes.loop
                    : false
                }
                onChange={(e) => handleAttributesChange("loop", e.target.checked)}
                inputProps={{ "aria-label": "toggle button" }}
              />
            </div>
            <div>
              <label>Muted:</label>
              <Switch
                checked={
                  selectedVideo && selectedVideo?.attributes.muted !== undefined
                    ? selectedVideo.attributes.muted
                    : false
                }
                onChange={(e) => handleAttributesChange("muted", e.target.checked)}
                inputProps={{ "aria-label": "toggle button" }}
              />
            </div>
            <div>
              <label>Poster URL:</label>
              <TextField
                label="Poster URL:"
                value={
                  selectedVideo &&
                  selectedVideo.attributes.poster &&
                  selectedVideo.attributes.poster[language]
                    ? selectedVideo.attributes.poster[language]
                    : ""
                }
                onChange={(e) => handleAttributesChange("poster", e.target.value)}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default forwardRef(VideoSelector);
