import { createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import { RootState, AppDispatch } from '../store';
import { fetchAllAssets, GetAssetsResponse } from '../../utils/api/assets';
import { generateReauthenticatingThunkApiAction } from './helpers';
import { IAssetItem } from "../../utils/interfaces/assets";

export type AssetState = {
  assets: Array<IAssetItem>;
  loading: boolean;
  error: string | null;
};
const initialState: AssetState = {
  assets: [],
  loading: false,
  error: null,
};

const getAllAssets = createAsyncThunk<
GetAssetsResponse,
null,
{
      dispatch: AppDispatch;
      state: RootState;
    }
>(
  "asset/getAllAssets",
  generateReauthenticatingThunkApiAction(async (state: RootState) => {
      try {
        const { uuid } = state.application.selectedApplication;
        const assets = await fetchAllAssets(uuid);
        return assets;
      } catch (error) {
          throw Error("Failed to fetch assets data");
      }
  })
);

const asset= createSlice({
  name: "asset",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
          builder
          .addCase(getAllAssets.pending, (state) => {
              state.loading = true;
              state.error = null;
          })
          .addCase(getAllAssets.fulfilled, (state, action) => {
              state.loading = false;
              state.assets = action.payload;
          })
          .addCase(getAllAssets.rejected, (state, action) => {
              state.loading = false;
              state.error = action.error.message || "Failed to fetch assets data";
          });
  },
});

export default asset.reducer;

export const actions = {
  ...asset.actions,
  getAllAssets,
};
