import axiosInstance from "../axiosInstance";
import { IArticle, ArticleFilterBy } from "../interfaces/article";
interface FetchFilterProps {
  filterBy?: ArticleFilterBy[];
  filterTerm: string;
  sortBy?: string;
  sortMode?: string;
  requestedLimit?: number;
  continuationToken?: string;
}

export const listArticle = async (edition_uuid: string): Promise<any> => {
  try {
    const response = await axiosInstance.post(
      `/article/admin/${edition_uuid}/find`
    );
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};
export const saveArticle = async (
  data: IArticle,
  edition_uuid: string
): Promise<any> => {
  try {
    const response = await axiosInstance.post(
      `/article/admin/${edition_uuid}/create`,
      data
    );
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to create article: ${error.response.data.message}`
      );
    } else {
      throw new Error("Failed to create article");
    }
  }
};
export const updateArticle = async (
  data: IArticle,
  uuid: string
): Promise<any> => {
  try {
    const response = await axiosInstance.patch(`/article/admin/${uuid}`, data);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to update article: ${error.response.data.message}`
      );
    } else {
      throw new Error("Failed to update article");
    }
  }
};
export const getArticle = async (article_uuid: string): Promise<any> => {
  try {
    const response = await axiosInstance.get(`/article/admin/${article_uuid}`);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to fetch article: ${error.response.data.message}`
      );
    } else {
      throw new Error("Failed to fetch article");
    }
  }
};
export const deleteArticle = async (article_uuid: string): Promise<any> => {
  try {
    const response = await axiosInstance.delete(
      `/article/admin/${article_uuid}`
    );
    return response;
  } catch (error: any) {
    return error.response;
  }
};
export const approveArticle = async (
  article_uuid: string,
  version_id: string
): Promise<any> => {
  try {
    const response = await axiosInstance.post(
      `/article/admin/approve/${article_uuid}/${version_id}`
    );
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to approve article: ${error.response.data.message}`
      );
    } else {
      throw new Error("Failed to find article");
    }
  }
};

export const deleteMultiArticle = async (
  uuids: Array<string>
): Promise<any> => {
  try {
    const response = await axiosInstance.post(`/article/admin/deleteMulti`, {
      uuids: uuids,
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
};
export const filterArticle = async (
  edition_uuid: string,
  body?: FetchFilterProps
): Promise<any> => {
  try {
    const response = await axiosInstance.post(
      `/article/admin/filter/${edition_uuid}`,
      body
    );
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};
