import { Application } from '../../utils/types';
import logger from '../../utils/logger';
import axiosInstance from '../axiosInstance';

export type GetApplicationsResponse = Array<Application>;
export const getAllApplications = async (email: string, accessToken: string | null): Promise<GetApplicationsResponse> => {
    logger('info', 'APPLICATIONS_GET_REQUEST');
    const res = await axiosInstance.get('/application/list');
    if (res.status === 401) {
        logger('error', 'APPLICATIONS_GET_FAILURE');
        throw new Error('Unauthorized');
    }
    return res.data;
};

export const getAllLanguages = async (): Promise<any> => {
    logger('info', 'APPLICATIONS_GET_ALL_LANGUAGES');
    const res = await axiosInstance.get('/globalLanguage/listAll');
    if (res.status === 200) {
        return res.data;
    }
};