import { FC, SetStateAction, useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../utils/hooks";
import {
  fetchUsers,
  selectUsers,
  deleteUser,
  selectUsersLoading,
  updateUserRoles,
} from "../../redux/slice/users.slice";
import styles from "./User.module.scss";
import { AppDispatch } from "../../redux/store";
import { Box, Typography, CircularProgress } from "@mui/material";
import { User, HeadCell } from "./userTypes";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { UserGridValues } from "./UserGridValues";
import { SearchBar } from "../../components/Search/SearchBar";
import CustomModal from "../../components/CustomModal";
import { UserRoleForm } from "./UserRoleForm";
import { Option } from "./UserRoleForm";
import {
  gridInitialPagination,
  pageSizeOptions,
  AUTHORIZED_ROLES,
  APPLICATION_CREATE,
  SeverityType,
} from "../../utils/constants/constants";
import Snackbar from "../../components/Snackbar/Snackbar";
import commonStyles from "../../styles.module.scss";
import { actions as applicationActions } from "../../redux/slice/application.slice";
import { selectRoleNames } from "../../redux/slice/roles.slice";

const Users: FC = () => {
  const { selectedApplication } = useAppSelector((state) => state.application);
  const { users: appUsers } = useAppSelector((state) => state.user);
  const { setSelectedApplication } = applicationActions;
  const authUser = useAppSelector((state) => state.auth);
  const dispatch: AppDispatch = useAppDispatch();
  const users = useAppSelector(selectUsers);
  const isLoading = useAppSelector(selectUsersLoading);
  const [rows, setRows] = useState<User[]>([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredRows, setFilteredRows] = useState<User[]>([]);
  const [modalFormValues, setModalFormValues] = useState({});
  const [checkedRole, setCheckedRole] = useState<string[]>([]);
  const [userToDelete, setUserToDelete] = useState<User | null>(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    SeverityType.Success | SeverityType.Error
  >(SeverityType.Success);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isShowActionButtons, setIsShowActionButtons] =
    useState<boolean>(false);
  const roleOptions = useAppSelector(selectRoleNames);

  useEffect(() => {
    if (selectedApplication.uuid) {
      dispatch(fetchUsers(selectedApplication.uuid));
    }
  }, [selectedApplication.uuid]);

  useEffect(() => {
    appUsers.length > 0 && checkAuthedUserRoles();
  }, [appUsers]);

  useEffect(() => {
    if (users && users.length > 0) {
      const modifiedData: any[] = users.map((user) => ({
        ...user,
        roles: user.roles.join(", "),
      }));
      setRows(modifiedData);
    }
  }, [users]);

  useEffect(() => {
    if (searchQuery === "" && filteredRows.length > 0) {
      setFilteredRows(rows);
    }
  }, [searchQuery]);

  const headCells: HeadCell[] = [
    {
      id: "firstName",
      numeric: false,
      disablePadding: false,
      label: "First Name",
    },
    {
      id: "lastName",
      numeric: false,
      disablePadding: false,
      label: "Last Name",
    },
    {
      id: "email",
      numeric: false,
      disablePadding: true,
      label: "Email",
    },
    {
      id: "uuid",
      numeric: false,
      disablePadding: false,
      label: "UUID",
    },
    {
      id: "roles",
      numeric: false,
      disablePadding: false,
      label: "Roles",
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: "",
    },
  ];

  const options =
    roleOptions &&
    roleOptions.map((role: string, index: number) => ({
      label: role,
      value: index,
    }));

  const handleSetRoles = (splittedRoles: string[]) => {
    setCheckedRole(splittedRoles as string[]);
  };

  const handleOpenEditModal = (rowValue: SetStateAction<{}>) => {
    setOpenEditModal(true);
    setModalFormValues(rowValue);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filteredData = rows.filter((row) => {
      return Object.keys(row).some((key) =>
        row[key as keyof User].toString().toLowerCase().includes(query)
      );
    });
    setFilteredRows(filteredData);
  };

  const checkApplicationOwnerConstraint = (
    currentRoles: string[],
    newRoles: string[]
  ): boolean => {
    const ownerCount = users.filter((user) =>
      user.roles.includes("Application Owner")
    ).length;
    const isCurrentUserOwner = currentRoles.includes("Application Owner");
    const removingOwnerRole =
      isCurrentUserOwner && !newRoles.includes("Application Owner");

    if (removingOwnerRole && ownerCount === 1) {
      return true;
    }

    return false;
  };

  const checkAuthedUserRoles = () => {
    const filteredUser = appUsers.filter((user) => {
      return user.email === authUser.email;
    });

    const isDisplay = AUTHORIZED_ROLES.some((role) => {
      return filteredUser[0].roles.includes(role);
    });
    setIsShowActionButtons(isDisplay);
  };

  const hideSnackbar = (
    hide: boolean = true,
    message: string = "",
    severity?: SeverityType
  ) => {
    setSnackbarMessage(message);
    setSnackbarSeverity((prevSeverity) => severity || prevSeverity);
    setOpenSnackbar(!hide);
  };
  const handleEditSave = async () => {
    setIsProcessing(true);
    try {
      const { uuid, roles: existingRolesString } = modalFormValues as any;

      const existingRoles = existingRolesString
        .split(",")
        .map((role: String) => role.trim());

      const rolesChanged =
        existingRoles.sort().join() !== checkedRole.sort().join();

      // Close the modal if there are no changes
      if (!rolesChanged) {
        handleCloseEditModal();
        return;
      }

      // Check if removing the last 'Application Owner' role is allowed
      const constraintViolated = checkApplicationOwnerConstraint(
        existingRoles,
        checkedRole
      );
      if (constraintViolated) {
        hideSnackbar(
          false,
          `"Application Owner" role is required for this application. Please ensure that at least one other user administering this application, has the "Application Owner" role applied before removing it from here.`,
          SeverityType.Error
        );
        handleCloseEditModal();
        return;
      }

      const updatedModalFormValues = {
        ...modalFormValues,
        roles: checkedRole.sort().join(", "),
      };

      const resultAction = await dispatch(
        updateUserRoles({
          appUUID: selectedApplication.uuid,
          userUUID: uuid,
          roles: checkedRole,
          modalFormValues: updatedModalFormValues,
        })
      );

      if (updateUserRoles.fulfilled.match(resultAction)) {
        const updatedUsers = selectedApplication.users.map((user) => {
          if (user.uuid === resultAction.payload.userUUID) {
            return {
              ...user,
              roles: resultAction.payload.roles,
            };
          }
          return user;
        });

        const updatedApp = {
          ...selectedApplication,
          users: updatedUsers,
        };

        dispatch(
          setSelectedApplication({
            currentApplication: updatedApp,
          })
        );

        handleCloseEditModal();
        hideSnackbar(
          false,
          "User roles updated successfully",
          SeverityType.Success
        );
      } else {
        hideSnackbar(false, "Failed to update user roles", SeverityType.Error);
      }
    } catch (error) {
      hideSnackbar(
        false,
        "Failed to update user roles. Please try again.",
        SeverityType.Error
      );
    } finally {
      setIsProcessing(false);
    }
  };

  const handleCloseSnackbar = () => {
    hideSnackbar();
  };

  const handleOpenDeleteModal = (rowValue: SetStateAction<{}>) => {
    setUserToDelete(rowValue as User);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleDeleteUser = () => {
    if (!userToDelete?.uuid || !selectedApplication.uuid) return;

    const constraintViolated = checkApplicationOwnerConstraint(
      userToDelete.roles,
      checkedRole
    );
    if (constraintViolated) {
      hideSnackbar(
        false,
        `"Application Owner" role is required for this application. Please ensure that at least one other user administering this application, has the "Application Owner" role applied before removing it from here.`,
        SeverityType.Error
      );
      handleCloseDeleteModal();
      return;
    }
    dispatch(
      deleteUser({
        appUUID: selectedApplication.uuid,
        userUUID: userToDelete.uuid,
      })
    );
    dispatch(fetchUsers(selectedApplication.uuid));
    handleCloseDeleteModal();
  };

  const renderCellExpand = (params: GridRenderCellParams<any, string>) => {
    let tableParams = params;
    if (params.field === "roles") {
      const splittedParamRoles = params.row.roles.split(",");
      const filteredParamsRoles = splittedParamRoles.filter((param: string) => {
        return param !== APPLICATION_CREATE;
      });

      const joinedParams = filteredParamsRoles.join(",");
      tableParams = {
        ...params,
        value: joinedParams,
        formattedValue: joinedParams,
      };
    }

    return (
      <UserGridValues
        params={tableParams}
        value={tableParams.value || ""}
        width={tableParams.colDef.computedWidth}
        label={tableParams.colDef.field ?? ""}
        handleOpenEditModal={handleOpenEditModal}
        handleOpenDeleteModal={handleOpenDeleteModal}
        isEditPermit={isShowActionButtons}
      />
    );
  };

  const columns = headCells.map((cell) => ({
    field: cell.id,
    headerName: cell.label,
    flex: 1,
    renderCell: renderCellExpand,
    cellClassName: styles.gridCell,
  }));

  return (
    <div className={styles.alignCenter}>
      <div className={styles.headline}>User Management</div>
      <Box className={styles.table_container}>
        {!isLoading ? (
          <>
            <SearchBar
              searchQuery={searchQuery}
              onSearchChange={handleSearchChange}
              placeholder="Search Users.."
            />
            {searchQuery.length > 0 && filteredRows.length === 0 ? (
              <div>No results found for your search.</div>
            ) : (
              <DataGrid
                className={styles.userDataGrid}
                getRowId={(row) => row.uuid}
                rows={
                  searchQuery && filteredRows.length > 0 ? filteredRows : rows
                }
                columns={columns}
                pageSizeOptions={pageSizeOptions}
                initialState={gridInitialPagination}
                checkboxSelection
                disableRowSelectionOnClick
                getRowHeight={() => "auto"}
                autoHeight
              />
            )}
          </>
        ) : rows.length === 0 ? (
          <div>No User Record Found</div>
        ) : (
          <div className={commonStyles.customLoading}>
            <CircularProgress size={60} />
          </div>
        )}
        <CustomModal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          title="Delete User"
          content=""
          onSave={handleDeleteUser}
          confirmText="Delete"
          cancelText="Cancel"
          isPositiveButton={false}
          isProcessing={isProcessing}
        >
          <>
            <div className={styles.modalContent}>
              <Box>
                <Typography variant="body1" component="p">
                  Are you sure that you want to delete this user from the
                  system?
                </Typography>
                <Typography
                  className={styles.deletedUser}
                  variant="body1"
                  component="p"
                >
                  {userToDelete?.firstName} {userToDelete?.lastName} |{" "}
                  {userToDelete?.email}
                </Typography>
              </Box>
            </div>
          </>
        </CustomModal>
        <CustomModal
          open={openEditModal}
          onClose={handleCloseEditModal}
          onSave={handleEditSave}
          title="Edit Roles"
          content=""
        >
          <Box className={styles.modalContent}>
            <UserRoleForm
              options={options as unknown as Option[]}
              modalFormValues={modalFormValues as User}
              handleSetRoles={handleSetRoles}
            />
          </Box>
        </CustomModal>
      </Box>
      <Snackbar
        open={openSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleCloseSnackbar}
      />
    </div>
  );
};

export default Users;
