import { PublicClientApplication } from "@azure/msal-browser";
import { RedirectRequest } from '@azure/msal-browser/dist/request/RedirectRequest';

const config: any = {
  auth: {
      clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
      authority: process.env.REACT_APP_AUTH_AUTHORITY,
      redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URL,
      postLogoutRedirectUri: process.env.REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URL,
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const protectedResources = {
  apiTest: {
    endpoint: process.env.REACT_APP_API_BASE_URL,
    scopes: {
      user_impersonation: [`api://`+ process.env.REACT_APP_AUTH_CLIENT_ID +`/user_impersonation`],
    },
  },
};
  
// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: [...protectedResources.apiTest.scopes.user_impersonation],
};

export const msalInstance = new PublicClientApplication(config)