import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { getUsers, updateUser, deleteUserInApp } from '../../utils/api/users';
import { User } from '../../utils/types';

interface UserState {
  users: User[];
  loading: boolean;
  error: string | null;
}

const initialState: UserState = {
  users: [],
  loading: false,
  error: null,
};

export const fetchUsers = createAsyncThunk(
  'user/fetchUsers',
  async (appUUID: string) => {
    try {
      const response = await getUsers(appUUID);
      return response;
    } catch (error) {
      throw Error('Failed to fetch users data');
    }
  }
);

export const updateUserRoles = createAsyncThunk(
  'user/updateUserRoles',
  async (
    {
      appUUID,
      userUUID,
      roles,
      modalFormValues,
    }: {
      appUUID: string;
      userUUID: string;
      roles: string[];
      modalFormValues: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await updateUser(appUUID, userUUID, roles);
      if (response.data === 'ok') {
        return { userUUID, roles, modalFormValues };
      } else {
        return rejectWithValue('Failed to update user role');
      }
    } catch (error) {
      return rejectWithValue('Failed to update user role');
    }
  }
);

export const deleteUser = createAsyncThunk(
  'user/deleteUser',
  async ({ appUUID, userUUID }: { appUUID: string; userUUID: string }) => {
    try {
      const response = await deleteUserInApp(appUUID, userUUID);
      return response;
    } catch (error) {
      throw Error('Failed to fetch users data');
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.users;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch users data';
      })
      .addCase(updateUserRoles.fulfilled, (state, action) => {
        const { userUUID, roles, modalFormValues } = action.payload;
        state.users = state.users.map((user) =>
          user.uuid === userUUID ? { ...modalFormValues, roles } : user
        );
      })
      .addCase(updateUserRoles.rejected, (state, action) => {
        state.error = action.payload as string;
      })
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.users;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to delete user';
      });
  },
});

export const selectUsers = (state: RootState) => state.user.users;
export const selectUsersLoading = (state: RootState) => state.user.loading;
export const selectUsersError = (state: RootState) => state.user.error;

export default userSlice.reducer;
