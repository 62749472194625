import React from "react";
import Box from "@mui/material/Box";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';
import { ThemeProvider } from "@mui/material/styles";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { BrowserRouter as Router } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./utils/helpers/authProvider";
import { globalTheme } from "./utils/theme/theme";

import AppConnected from "./pages/App";
import styles from "./styles.module.scss";

const reactPlugin = new ReactPlugin();

export default function AppWrapper() {
  return (
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <React.Fragment>
            <Box className={styles.mainContainer} alignItems="center">
              <Router basename={process.env.PUBLIC_URL}>
                <ThemeProvider theme={globalTheme}>
                  <AppInsightsContext.Provider value={reactPlugin}>
                    <AppConnected />
                  </AppInsightsContext.Provider>
                </ThemeProvider>
              </Router>
            </Box>
          </React.Fragment>
        </PersistGate>
      </Provider>
    </MsalProvider>
  );
}
