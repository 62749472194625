import logger from '../../utils/logger';
import axiosInstance from '../axiosInstance';

export const getUsers = async (appUUID: string): Promise<any> => {
  logger('info', 'USERS_GET_REQUEST');
  try {
    const response = await axiosInstance.get(
      `/application/${appUUID}/usersList`
    );

    if (response.status === 401) {
      logger('error', 'APPLICATIONS_GET_FAILURE');
      throw new Error('Unauthorized');
    }
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch users data');
  }
};

export const deleteUserInApp = async (
  appUUID: string,
  userUUID: string
): Promise<any> => {
  logger('info', 'USERS_DELETE_REQUEST');
  logger('info', `appUUID: ${appUUID}, userUUID: ${userUUID}`);
  try {
    const response = await axiosInstance.delete(
      `/application/${appUUID}/user/${userUUID}`
    );
    logger('info', response.data);

    if (response.status === 401) {
      logger('error', 'APPLICATIONS_GET_FAILURE');
      throw new Error('Unauthorized');
    }
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch users data');
  }
};

export const updateUser = async (appUUID: string, userUUID: string, roles: string[]): Promise<any> => {
    logger('info', 'USER_UPDATE_REQUEST');
    try {
        const response = await axiosInstance.post(`/application/${appUUID}/userRoleUpdate/${userUUID}`, {
            roles
        });

        if (response.status === 401) {
            logger('error', 'USER_UPDATE_FAILURE');
            throw new Error('Unauthorized');
        }

        logger('info', 'USER_UPDATE_SUCCESS');
        return response.data;
    } catch (error) {
        logger('error', 'USER_UPDATE_FAILURE');
        throw new Error('Failed to update user roles');
    }
};
