import { Button, Grid, Box } from "@mui/material";
import styles from "./CustomModal.module.scss";

interface ModalFooterProps {
  handleConfirm: () => void;
  handleClose: () => void;
  confirmText?: string;
  cancelText?: string;
  saveIcon?: JSX.Element;
  closeIcon?: JSX.Element;
  isPositiveButton?: boolean;
  ignoreCancelButton?: boolean;
  isCancelDisabled?: boolean;
  isProcessing?: boolean;
  hasBorder?: boolean;
  isConfirmationButtonRight?: boolean;
  hasPadding?: boolean;
}

export const ModalFooter = ({
  handleConfirm,
  handleClose,
  confirmText = "Confirm",
  cancelText = "Cancel",
  closeIcon,
  saveIcon,
  isPositiveButton = false,
  isProcessing = false,
  isCancelDisabled = false,
  ignoreCancelButton = false,
  hasBorder = false,
  isConfirmationButtonRight = false,
  hasPadding = true,
}: ModalFooterProps) => {
  return (
    <Box>
      <Grid
        container
        className={`${styles.modalActions} ${
          hasBorder ? styles.hasBorderFooter : ""
        } ${hasPadding ? styles.noPadding : ""}`}
      >
        {!ignoreCancelButton && (
          <Grid item xs={6} className={styles.modalCancelContainer}>
            <Button
              className={`${styles.modalButton} ${styles.cancelButton}`}
              onClick={handleClose}
              color="primary"
              variant="outlined"
              disabled={isCancelDisabled}
            >
              {cancelText} {closeIcon ? closeIcon : null}
            </Button>
          </Grid>
        )}
        <Grid
          item
          xs={ignoreCancelButton ? 12 : 6}
          className={`${styles.modalConfirmContainer} ${
            ignoreCancelButton
              ? isConfirmationButtonRight
                ? styles.right
                : styles.center
              : ""
          }`}
        >
          <Button
            className={`${styles.modalButton} ${styles.okButton} ${
              !isPositiveButton ? styles.deleteButton : ""
            }`}
            onClick={handleConfirm}
            disabled={isProcessing}
          >
            {confirmText} {saveIcon ? saveIcon : null}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
