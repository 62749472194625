import logger from '../logger';
import axiosInstance from '../axiosInstance';

export const getAllRole = async (): Promise<any> => {
    logger('info', 'ROLES_GET_REQUEST');
    try {
        const response = await axiosInstance.get(`/rbac/role/list`);

        if (response.status === 401) {
            logger('error', 'ROLES_GET_FAILURE');
            throw new Error('Unauthorized');
        }
        return response.data;
    } catch (error) {
        throw new Error('Failed to fetch roles data');
    }
};
