import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { AccountInfo, AuthenticationResult } from "@azure/msal-browser";
import { loginRequest } from "../authProvider/authProvider";

export const useAccessToken = (): string | null => {
	const { instance, accounts } = useMsal();
	const [accessToken, setAccessToken] = useState<string | null>(null);

	if (accounts.length > 0) {
		const request = {
			scopes: loginRequest.scopes,
			account: accounts[0] as AccountInfo, // Ensure proper typing for account
		};
		
		instance
			.acquireTokenSilent(request)
			.then((response: AuthenticationResult) => {
				setAccessToken(response.accessToken);
			})
			.catch((error) => {
				// acquireTokenSilent can fail for a number of reasons, fallback to interaction
				if (error instanceof InteractionRequiredAuthError) {
					instance.acquireTokenPopup(request).then((response: AuthenticationResult) => {
						setAccessToken(response.accessToken);
					});
				}
			});
	}

	return accessToken;
};
