import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { getAllRole } from "../../utils/api/role";
import { Role } from "../../utils/types";

type roleObject = {
    [key: string]: string[];
};

interface roleState {
    roleNames: string[];
    roles: roleObject;
    loading: boolean;
    error: string | null;
}

const initialState: roleState = {
    roleNames: [],
    roles: {},
    loading: false,
    error: null,
};

interface FinalObject {
    [key: string]: string[];
}

const createFinalObject = (roles: Role[]): FinalObject => {
    const finalObject: FinalObject = {};
    roles.forEach((role: any) => {
        role.permissions.forEach((permission: any) => {
            const prefix = permission.name.split('.')[0];
            if (!finalObject.hasOwnProperty(`${prefix}`)) {
                finalObject[`${prefix}`] = [];
            }
            if (!finalObject[`${prefix}`].includes(permission.name)) {
                finalObject[`${prefix}`].push(permission.name);
            }
        });
    });
    return finalObject;
}

export const fetchRoles = createAsyncThunk(
    "role/fetchRoles",
    async () => {
        try {
            const response = await getAllRole();
            return response;
        } catch (error) {
            throw Error("Failed to fetch roles data");
        }
    }
);

const roleSlice = createSlice({
    name: "role",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRoles.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchRoles.fulfilled, (state, action) => {
                state.loading = false;
                const roles = action.payload
                const roleNames = roles.map((role :{name:string}) => role.name)
                state.roleNames = roleNames
                const finalObject: FinalObject = createFinalObject(roles);
                state.roles = finalObject;
            })
            .addCase(fetchRoles.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || "Failed to fetch roles data";
            });
    },
});


export const selectRoles = (state: RootState) => state.roles.roles;
export const selectRoleNames = (state: RootState) => state.roles.roleNames;
export const selectRolesLoading = (state: RootState) => state.roles.loading;
export const selectRolesError = (state: RootState) => state.roles.error;

export default roleSlice.reducer;
