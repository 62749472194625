import React, { useEffect, useRef, useState } from 'react';
import { Box, Popper, Paper, Typography, Button } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import styles from './User.module.scss';

interface GridCellExpandProps {
  value: string;
  width: number;
  label: string;
  isEditPermit: boolean;
  handleOpenEditModal: (value: string[]) => void;
  handleOpenDeleteModal: (value: string[]) => void;
  params: GridRenderCellParams;
}

function isOverflown(element: Element): boolean {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

export const UserGridValues = React.memo(function GridCellExpand(
  props: GridCellExpandProps
) {
  const {
    width,
    value,
    label,
    handleOpenEditModal,
    handleOpenDeleteModal,
    isEditPermit,
    params,
  } = props;
  const wrapper = useRef<HTMLDivElement | null>(null);
  const cellDiv = useRef(null);
  const cellValue = useRef(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showFullCell, setShowFullCell] = useState(false);
  const [showPopper, setShowPopper] = useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current!);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent: KeyboardEvent) {
      if (nativeEvent.key === 'Escape') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  const handleSubmitValues = () => {
    handleOpenEditModal(params.row);
  };

  const handleDeleteUser = () => {
    handleOpenDeleteModal(params.row);
  };

  return (
    <Box
      ref={wrapper}
      className={styles.gridCellExpand}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box
        ref={cellDiv}
        className={styles.gridCellExpandCell}
        sx={{
          width,
        }}
      />

      <Box ref={cellValue} className={styles.gridCellExpandValue}>
        {label !== 'actions'
          ? value
          : isEditPermit && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleSubmitValues}
                  endIcon={<EditIcon />}
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleDeleteUser}
                  endIcon={<DeleteIcon />}
                >
                  Delete
                </Button>
              </>
            )}
      </Box>

      {showPopper && (
        <Popper open={showFullCell && anchorEl !== null} anchorEl={anchorEl}>
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current!.offsetHeight - 3 }}
          >
            <Typography variant='body2' style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});
