import React, { useState } from "react";
import {
  Button,
  Box,
  Checkbox,
  Grid,
  styled,
  IconButton,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import styles from "./ListArticles.module.scss";
import commonStyles from "../../../styles.module.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { formatDate, getLangData } from "../../../utils/generic";
import { IArticleResponse } from "../../../utils/interfaces/article";
import OptionMenu from "../../OptionMenu/OptionMenu";
import { useAppSelector } from "../../../utils/hooks";
import { ArticleStatus } from "../";
import { ArticleStatus as status } from '../../../utils/constants/constants';

interface ListArticlesProps {
  articleData: IArticleResponse;
  viewMode: "grid" | "list";
  isSelected: boolean;
  onSelect: (id: string) => void;
  onEdit: (uuid: string) => void;
  onDuplicate: (uuid: string) => void;
  onDelete: (uuid: string) => void;
  onApprove: (uuid: string) => void;
}

const ListArticles: React.FC<ListArticlesProps> = ({
  articleData,
  viewMode,
  isSelected,
  onSelect,
  onEdit,
  onDuplicate,
  onDelete,
  onApprove,
}) => {
  const article = articleData.currentVersion;
  const { selectedApplication } = useAppSelector(
    (state: any) => state.application
  );
  const userPermissions = selectedApplication.permissions;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [menuDisabled, setMenuDisabled] = useState<boolean>(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const handleEditAndClose = (uuid: string) => {
    onEdit(uuid);
    handleClose();
  };

  const handleCancelCancelled = () => {
		setConfirmationOpen(false);
	};

  const handleApprove = (uuid: string) => {    
    if (article.status === status.Draft || article.status === status.Approved) {
      setConfirmationOpen(true);
      return;
    }
		onApprove(uuid);
		handleClose();
  }
  const handleDuplicateAndClose = (uuid: string) => {
    onDuplicate(uuid);
    handleClose();
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <>
        {viewMode === "grid" ? (
          <Box>
            <Box
              className={`${styles["item-renderer"]} ${commonStyles["standardBoxShadow"]}`}
            >
              <div className={styles["item-overlay"]}>
                <div className={styles["date-info"]}>
                  <Checkbox
                    checked={isSelected}
                    onChange={() => { setMenuDisabled(menuDisabled ? false : true); onSelect(articleData.uuid) }}
                    color="primary"
                    className={`${styles["select-checkbox"]}`}
                  />
                  <div>
                    <div><span className={styles["date-level"]}>Created</span> {formatDate(articleData.createdAt)}</div>
                    <div><span className={styles["date-level"]}>Modified</span> {formatDate(articleData.updatedAt)}</div>
                  </div>
                </div>
                <Box className={styles["background"]}>
                  <p title={getLangData(article.title)}>{getLangData(article.title)}</p>
                  <div className={styles["language"]}>EN</div>
                </Box>
                <div className={styles["article-info"]}>
                  <ArticleStatus status={article.status} />
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    disabled={menuDisabled}
                  >
                   <MoreVertIcon className={menuDisabled ? `${styles['options-inactive']}` : `${styles['options-active']}`} />
                  </IconButton>
                  <OptionMenu
                    anchorEl={anchorEl}
                    open={open}
                    handleClose={handleClose}
                  >
                    {userPermissions?.includes(
                      "ArticleCollections.UpdateArticle"
                    ) && (
                        <MenuItem
                          onClick={() => handleEditAndClose(articleData.uuid)}
                        >
                          Edit <BorderColorOutlinedIcon />
                        </MenuItem>
                      )}
                    {userPermissions?.includes(
                      "ArticleCollections.CreateArticle"
                    ) && (
                        <MenuItem
                        onClick={() => handleDuplicateAndClose(articleData.uuid)}
                      >
                          Duplicate <ContentCopyOutlinedIcon />
                        </MenuItem>
                      )}
                    {userPermissions?.includes(
                      "ArticleCollections.ApproveArticle"
                    ) && (
                        <MenuItem onClick={() => handleApprove(articleData.uuid)}>
                          Approve <ThumbUpAltOutlinedIcon />
                        </MenuItem>
                      )}
                    {userPermissions?.includes(
                      "ArticleCollections.DeleteArticle"
                    ) && (
                        <MenuItem
                          onClick={() => {
                            onDelete(articleData.uuid);
                            handleClose();
                          }}
                        >
                          Delete <DeleteOutlineOutlinedIcon />
                        </MenuItem>
                      )}
                  </OptionMenu>
                </div>
              </div>
            </Box>
          </Box>
        ) : (
          <div className={`${styles["list-item-renderer"]}`}>
            <Grid container className={styles["list-article-info"]}>
              <Grid item xs={0.5} className={styles["content-center"]}>
                <Checkbox
                  checked={isSelected}
                  onChange={() => { setMenuDisabled(menuDisabled ? false : true); onSelect(articleData.uuid) }}
                  color="primary"
                  defaultChecked
                  className={`${styles["select-checkbox"]}`}
                />
              </Grid>

              <Grid item xs={4} className={styles["content-column"]}>
                <div className={styles["list-header"]}>Title</div>
                <div className={styles["list-title"]} title={getLangData(article.title)}>
                  {getLangData(article.title)}
                </div>
              </Grid>
              <Grid item xs={2} className={styles["content-column"]}>
                <div className={styles["list-header"]}>Languages</div>
                <div className={styles["language"]}>EN</div>
              </Grid>
              <Grid item xs={2} className={styles["content-column"]}>
                <div className={styles["list-header"]}>Created</div>
                <div className={styles["list-date"]}>
                  {formatDate(articleData.createdAt)}
                </div>
              </Grid>
              <Grid item xs={2} className={styles["content-column"]}>
                <div className={styles["list-header"]}>Modified</div>
                <div className={styles["list-date"]}>
                  {formatDate(articleData.updatedAt)}
                </div>
              </Grid>
              <Grid item xs={1} className={styles["content-column"]}>
                <div className={styles["list-header"]}>Status</div>
                <>
                  <ArticleStatus status={article.status} />
                </>
              </Grid>
              <Grid item xs={0.5}>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                  disabled={menuDisabled}
                >
                  <MoreVertIcon className={menuDisabled ? `${styles['options-inactive']}` : `${styles['options-active']}`} />
                </IconButton>

                <OptionMenu
                  anchorEl={anchorEl}
                  open={open}
                  handleClose={handleClose}
                >
                  {userPermissions?.includes(
                    "ArticleCollections.UpdateArticle"
                  ) && (
                      <MenuItem
                        onClick={() => handleEditAndClose(articleData.uuid)}
                      >
                        Edit <BorderColorOutlinedIcon />
                      </MenuItem>
                    )}
                  {userPermissions?.includes(
                    "ArticleCollections.CreateArticle"
                  ) && (
                      <MenuItem
                      onClick={() => handleDuplicateAndClose(articleData.uuid)}
                    >
                        Duplicate <ContentCopyOutlinedIcon />
                      </MenuItem>
                    )}
                  {userPermissions?.includes(
                    "ArticleCollections.ApproveArticle"
                  ) && (
                      <MenuItem onClick={() => handleApprove(articleData.uuid)}>
                        Approve <ThumbUpAltOutlinedIcon />
                      </MenuItem>
                    )}
                  {userPermissions?.includes(
                    "ArticleCollections.DeleteArticle"
                  ) && (
                      <MenuItem
                        onClick={() => {
                          onDelete(articleData.uuid);
                          handleClose();
                        }}
                      >
                        Delete <DeleteOutlineOutlinedIcon />
                      </MenuItem>
                    )}
                </OptionMenu>
              </Grid>
            </Grid>
          </div>
        )}
      </>
      <Dialog open={confirmationOpen} onClose={handleCancelCancelled}>
        <DialogTitle>Information</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Articles that are in pending status only can be approved.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelCancelled} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ListArticles;
