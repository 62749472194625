import { useState, ChangeEvent, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import CustomModal from "../../components/CustomModal";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, TextField } from "@mui/material";
import { AssetListItem } from "./AssetListItem";
import { IAssetProps } from "../../utils/interfaces/assets";
import AddIcon from "@mui/icons-material/Add";
import ReplayIcon from "@mui/icons-material/Replay";
import styles from "./Assets.module.scss";
import { SeverityType } from "../../utils/constants/constants";
import { uploadAsset } from "../../utils/api/assets";
import { useAppSelector, useAppDispatch } from "../../utils/hooks";
import Snackbar from "../../components/Snackbar/Snackbar";
import { handleFileProcessing } from "../../utils/helpers/assets/assetHelpers";
import { UploadProgress } from "../../components/AssetComponents/UploadProgress";
import EditAsset from "../../components/AssetComponents/EditAsset";
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { actions } from '../../redux/slice/app.slice';

interface DigitalAssetModalProps {
  selectedAssetData: any[];
  openAssetModal: boolean;
  handleCloseAssetModal: () => void;
  handleUpdateAssetList: () => void;
  getAllAssets: any;
}
interface UploadState {
  progress: { [uuid: string]: number };
  failedUploads: { [uuid: string]: boolean };
  successUploads: { [uuid: string]: boolean };
  fadeOutItems: { [uuid: string]: boolean };
}

const initialUploadState: UploadState = {
  progress: {},
  failedUploads: { dummyProperty: false },
  successUploads: { dummyProperty: false },
  fadeOutItems: {},
};

export const AssetUploadModal = ({
  selectedAssetData,
  openAssetModal,
  handleCloseAssetModal,
  handleUpdateAssetList,
  getAllAssets,
}: DigitalAssetModalProps) => {
  const [commonTags, setCommonTags] = useState<string>("");
  const [assetData, setAssetData] = useState<any>(selectedAssetData);
  const { selectedApplication } = useAppSelector((state) => state.application);
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadTriggered, setUploadTriggered] = useState<boolean>(false);
  const { tracking } = actions;
  const dispatch = useAppDispatch();
  const appInsights = useAppInsightsContext();
  const [uploadState, setUploadState] =
    useState<UploadState>(initialUploadState);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [snackbarState, setSnackbarState] = useState<{
    open: boolean;
    message: string;
    severity: SeverityType.Success | SeverityType.Error;
  }>({
    open: false,
    message: "",
    severity: SeverityType.Success,
  });
  const [singleUploadState, setSingleUploadState] = useState({
    isSingleUpload: false,
    title: "",
    description: "",
  });
  const [openCancelConfirmModal, setOpenCancelConfirmModal] =
    useState<boolean>(false);
    
  const hasError = assetData.some((asset : IAssetProps) => asset.errors === true);

  const handleFadeOut = (uuid: string) => {
    setUploadState((prevState) => ({
      ...prevState,
      fadeOutItems: { ...prevState.fadeOutItems, [uuid]: true },
    }));
    setTimeout(() => {
      handleDeleteAsset(uuid);
    }, 3000);
  };

  const handleCloseSnackbar = () => {
    hideSnackbar();
  };

  const cleanUp = () => {
    setAssetData([]);
    setCommonTags("");
    setSingleUploadState((prevState) => ({
      ...prevState,
      isSingleUpload: false,
    }));
    setUploadTriggered(false);
    setUploadState(initialUploadState);
  };

  const handleCloseModal = (allSuccess = false) => {
    if (allSuccess) {
      cleanUp();
      handleCloseAssetModal();
    } else {
      assetData.length === 0
        ? handleCloseAssetModal()
        : setOpenCancelConfirmModal(true);
    }
  };

  const hideSnackbar = (
    hide: boolean = true,
    message: string = "",
    severity?: SeverityType
  ) => {
    setSnackbarState((prevState) => ({
      open: !hide,
      message: message,
      severity: severity || prevState.severity, 
    }));
  };

  const uploadFiles = async (data: IAssetProps[]) => {
    setUploading(true);

    // Initialize failed and success uploads
    setUploadState((prevState) => ({
      ...prevState,
      failedUploads: data.reduce(
        (acc: { [uuid: string]: boolean }, fileData: IAssetProps) => {
          acc[fileData.uuid] = false;
          return acc;
        },
        {}
      ),
      successUploads: data.reduce(
        (acc: { [uuid: string]: boolean }, fileData: IAssetProps) => {
          acc[fileData.uuid] = false;
          return acc;
        },
        {}
      ),
    }));

    const {
      isSingleUpload,
      title: singleAssetTitle,
      description: singleAssetDesc,
    } = singleUploadState;
    try {
      if (isSingleUpload) {
        const [fileData] = data;
        fileData.title = singleAssetTitle;
        fileData.fileName = singleAssetDesc;
        await uploadFile(fileData, selectedApplication.uuid);
      } else {
        await Promise.allSettled(
          data.map((fileData: IAssetProps) =>
            uploadFile(fileData, selectedApplication.uuid)
          )
        );
      }
      const assetList = await getAllAssets();
          dispatch(
            tracking({
              appInsights,
              name: 'debug-user',
              payload: assetList ? { type: 'getAllAssets', data: assetList } : { type: 'no getAllAssets' },
            }),
          )
    } catch (error) {
      hideSnackbar(false, "Error uploading files", SeverityType.Error);
    } finally {
      isSingleUpload
        ? setUploading(false)
        : setTimeout(() => {
            setUploading(false);
          }, 3000);
      setUploadTriggered(true);
    }
  };

  const uploadFile = (fileData: any, app_uuid: string) => {
    const { uuid } = fileData;
    return new Promise((resolve, reject) => {
      uploadAsset(fileData, app_uuid, (progressEvent: any) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadState((prevState) => ({
          ...prevState,
          progress: { ...prevState.progress, [uuid]: percentCompleted },
        }));
      })
        .then((response: any) => {
          if (response.status === 200) {
            setUploadState((prevState) => ({
              ...prevState,
              successUploads: { ...prevState.successUploads, [uuid]: true },
              failedUploads: { ...prevState.failedUploads, [uuid]: false },
            }));
            resolve(response);
          } else {
            setUploadState((prevState) => ({
              ...prevState,
              failedUploads: { ...prevState.failedUploads, [uuid]: true },
              progress: { ...prevState.progress, [uuid]: 0 },
            }));
            reject(new Error("Upload failed"));
          }
        })
        .catch((error: any) => {
          setUploadState((prevState) => ({
            ...prevState,
            failedUploads: { ...prevState.failedUploads, [uuid]: true },
            progress: { ...prevState.progress, [uuid]: 0 },
          }));
          reject(error);
        });
    });
  };

  const handleSaveAssets = () => {
    
    const initialProgress = assetData.reduce(
      (acc: { [uuid: string]: number }, data: IAssetProps) => {
        acc[data.uuid] = 0;
        return acc;
      },
      {}
    );
    setUploadState((prevState) => ({
      ...prevState,
      progress: initialProgress,
    }));
    const data = assetData.map((data: IAssetProps) => {
      URL.revokeObjectURL(data.url);
      return { ...data, tags: commonTags };
    });
    uploadFiles(data);
  };

  const handleSelectAssets = async (
    e: ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement> | any,
    droppedFiles?: FileList | File[]
  ) => {
    const files =
      droppedFiles && droppedFiles.length > 0 ? droppedFiles : e.target.files;

    const processedFiles = await handleFileProcessing(files);
    setAssetData((prevItems:any) => [...prevItems, ...processedFiles]);
    handleUploadMode(processedFiles);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleUploadMode = (filteredFiles: File[]) => {
    if (assetData.length > 0) {
      setSingleUploadState({
        ...singleUploadState,
        isSingleUpload: false,
      });
    } else if (filteredFiles.length > 1 && assetData.length === 0) {
      setSingleUploadState({
        ...singleUploadState,
        isSingleUpload: false,
      });
    } else if (filteredFiles.length === 1 && assetData.length === 0) {
      setSingleUploadState({
        ...singleUploadState,
        isSingleUpload: true,
        title: filteredFiles[0].name,
        description: filteredFiles[0].name,
      });
    }
  };

  const handleChangeTags = (e: ChangeEvent<HTMLInputElement>) => {
    setCommonTags(e.target.value);
  };

  const handleDeleteAsset = (deleteIndex: string) => {
    setAssetData((prevItems: IAssetProps[]) => {
      if (prevItems.length === 0) return [];

      const updatedItems = prevItems.filter(
        (item) => item.uuid !== deleteIndex
      );

      const keys = Object.keys(uploadState.failedUploads);

      if (
        updatedItems.length === 1 &&
        keys.length === 1 &&
        !uploadState.failedUploads[keys[keys.length - 1]]
      ) {
        setSingleUploadState({
          ...singleUploadState,
          isSingleUpload: true,
          title: updatedItems[0].fileName,
          description: updatedItems[0].fileName,
        });
      }

      return updatedItems;
    });

    setUploadState((prevState) => {
      const { [deleteIndex]: _, ...restFailedUploads } =
        prevState.failedUploads;
      return { ...prevState, failedUploads: restFailedUploads };
    });
  };

  const handleEditAssetTitle = (uuid: string, title: string) => {
    setAssetData((prevItems: IAssetProps[]) => {
      return prevItems.map((item, i) => {
        if (item.uuid === uuid) {
          return { ...item, title };
        }
        return item;
      });
    });
  };

  const handleUploadByDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer?.files;
    if (droppedFiles && droppedFiles.length > 0) {
      const newFiles = Object.values(droppedFiles as FileList);
      handleSelectAssets(e, newFiles);
    }
  };

  const cancelConfirmNoHandler = () => {
    setOpenCancelConfirmModal(false);
    handleUpdateAssetList();
  };

  const cancelConfirmYesHandler = () => {
    setOpenCancelConfirmModal(false);
    handleCloseAssetModal();
    cleanUp();
    handleUpdateAssetList();
  };

  useEffect(() => {
    if (selectedAssetData.length === 1) {
      setSingleUploadState({
        ...singleUploadState,
        isSingleUpload: true,
        title: selectedAssetData[0].name,
        description: selectedAssetData[0].name,
      });
    } else {
      setSingleUploadState({
        ...singleUploadState,
        isSingleUpload: false,
      });
    }
    setAssetData(selectedAssetData);
  }, [selectedAssetData]);

  useEffect(() => {
    const allSuccessful = Object.values(uploadState.successUploads).every(
      (uploadStatus) => uploadStatus === true
    );
    if (allSuccessful) {
      setUploading(false);
      handleCloseModal(true);
      hideSnackbar(
        false,
        "All assets uploaded successfully",
        SeverityType.Success
      );
      handleUpdateAssetList();
      cleanUp();
    }

    Object.keys(uploadState.successUploads).forEach((uuid) => {
      if (uploadState.successUploads[uuid] && !uploadState.fadeOutItems[uuid]) {
        handleFadeOut(uuid);
      }
    });
  }, [uploadState]);

  const handleFileData = (fileData: any) => {
    setSingleUploadState({
      ...singleUploadState,
      title: fileData.caption,
      description: fileData.alt,
    });
    setCommonTags(fileData.tags.join(","));
  };
  return (
    <>
      <CustomModal
        open={openAssetModal}
        onClose={() => handleCloseModal(false)}
        title="Upload Assets"
        content=""
        onSave={handleSaveAssets}
        cancelText="Cancel"
        isPositiveButton={true}
        headerIcon={<FileUploadIcon />}
        saveIcon={uploadTriggered ? <ReplayIcon /> : <CheckIcon />}
        closeIcon={<CloseIcon />}
        isProcessing={assetData.length === 0 || uploading || hasError}
        isCancelDisabled={uploading}
        confirmText={uploadTriggered ? "Try Again" : "Confirm"}
      >
        <Box className={styles.digitalAssetContent}>
          <div
            onDrop={handleUploadByDrop}
            onDragOver={(e) => e.preventDefault()}
          >
            <Box
              className={`${styles.imageList} ${
                !singleUploadState.isSingleUpload
                  ? styles.scrollableContainer
                  : ""
              }`}
            >
              {assetData && assetData.length > 0 ? (
                singleUploadState.isSingleUpload ? (
                  <EditAsset
                    asset={assetData[0]}
                    onFileDataChange={handleFileData}
                    singleUploadProgress={ uploadState.progress[assetData[0].uuid]}
                    isSingleUpload = {true}
                    isNew={true}
                  ></EditAsset>
                ) : (
                  assetData.map((data: IAssetProps) => (
                    <Box
                      key={data.uuid}
                      className={`${styles.assetContainer} ${
                        uploadState.fadeOutItems[data.uuid]
                          ? styles.fadeOut
                          : ""
                      }`}
                    >
                      <AssetListItem
                        {...data}
                        handleDeleteAsset={handleDeleteAsset}
                        handleEditAssetTitle={handleEditAssetTitle}
                        uploadError={uploadState.failedUploads[data.uuid]}
                      />
                      <UploadProgress
                        uuid={data.uuid}
                        progress={uploadState.progress[data.uuid]}
                        failedUploads={uploadState.failedUploads}
                        successUploads={uploadState.successUploads}
                        uploading={uploading}
                      />
                    </Box>
                  ))
                )
              ) : (
                <Box>
                  No assets uploaded yet. Drag and drop assets or click Add
                  button. Allowed formats : image or video
                </Box>
              )}
            </Box>
          </div>
          <Box className={styles.actionContainer}>
            <Grid container spacing={2}>
              <Grid item lg={5}>
                <Button
                  className={styles.modalButton}
                  component="label"
                  role={undefined}
                  variant="outlined"
                  tabIndex={-1}
                  disabled={uploading}
                >
                  Add Upload <AddIcon />
                  <input
                    ref={fileInputRef}
                    className={styles.hiddenInput}
                    type="file"
                    onChange={(e) => handleSelectAssets(e)}
                    multiple={true}
                    accept="image/*, video/*"
                  />
                </Button>
              </Grid>
              {!singleUploadState.isSingleUpload && (
                <Grid item lg={7}>
                  <TextField
                    disabled={uploading}
                    label="Common Tags"
                    variant="outlined"
                    size="small"
                    onChange={handleChangeTags}
                    fullWidth
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
        <CustomModal
          open={openCancelConfirmModal}
          onClose={cancelConfirmNoHandler}
          onSave={cancelConfirmYesHandler}
          title="Confirmation"
          content="Are you sure you want to cancel uploading the assests"
          confirmText="Yes"
          cancelText="No"
          ignoreCancelButton={false}
          isSmall={true}
        ></CustomModal>
      </CustomModal>
      <Snackbar
        open={snackbarState.open}
        message={snackbarState.message}
        severity={snackbarState.severity}
        onClose={handleCloseSnackbar}
      />
    </>
  );
};
