import { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import commonStyles from "../../styles.module.scss";
import styles from "./home.module.scss";
import { useAppSelector, useAppDispatch } from "../../utils/hooks";
import { Application, Edition } from "../../utils/types";
import { actions as applicationActions } from "../../redux/slice/application.slice";
import { actions as editionActions } from "../../redux/slice/edition.slice";
import { SUPPORT_TEAM_EMAIL } from "../../utils/constants/constants";
import { BaseButton } from "../../utils/ui/buttons";
import ChevronRightSharp from "@mui/icons-material/ChevronRightSharp";
import { actions } from '../../redux/slice/app.slice';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

interface Props {
  moduleName?: string;
  getAllAssets: any;
}

const Home: FC<Props> = ({
  getAllAssets,
}: Props): JSX.Element => {
  const { setSelectedApplication } = applicationActions;
  const { setSelectedEdition } = editionActions;
  const [currentApplication, setCurrentApplication] = useState<
    Application | any
  >({});
  const [appSelected, setAppSelected] = useState<boolean>(false);
  const [editionSelected, setEditionSelected] = useState<boolean>(false);
  const [currentEdition, setCurrentEdition] = useState<Edition | any>({});
  const [editions, setEditionList] = useState<Edition[] | any[]>([]);
  const { applications } = useAppSelector((state) => state.application);
  const [selectedApp, setSelectedApp] = useState("");
  const [selectedEditionValue, setSelectedEditionValue] = useState("");

  const { tracking } = actions;
  const dispatch = useAppDispatch();
  const appInsights = useAppInsightsContext();
  const navigate = useNavigate();
  
  const handleSubmit = async () => {
    if ( currentApplication?.permissions?.some((item: string) =>
        item.includes("MediaLibrary.ReadMedia")
      ))
    {
      const assetList = await getAllAssets();
      dispatch(
        tracking({
          appInsights,
          name: 'debug-user',
          payload: assetList ? { type: 'getAllAssets', data: assetList } : { type: 'no getAllAssets' },
        }),
      )
    }
    //which page user should go  as per permissions
    if (
      currentApplication?.permissions?.some((item: string) =>
        item.includes("Application.")
      )
    ) {
      navigate("/users");
    } else {
      navigate("/articles");
    }
  };

  useEffect(() => {
    if (currentApplication) {
      dispatch(
        setSelectedApplication({
          currentApplication: currentApplication,
        })
      );
    }
  }, [currentApplication]);

  useEffect(() => {
    if (currentEdition) {
      dispatch(
        setSelectedEdition({
          currentEdition: currentEdition,
        })
      );
      
    }
  }, [currentEdition]);

  const handleApplicationChange = (event: SelectChangeEvent) => {
    setEditionSelected(false);
    setSelectedEditionValue("");
    setAppSelected(true);
    const appUUID: string = event.target.value;
    localStorage.setItem("selectedAppUUID", appUUID); // Save selected app uuid to local storage, as we can't use redux store inside the axios interceptor
    const filteredApp: Application[] = applications.filter(
      (app: Application): boolean => app.uuid === appUUID
    );
    if (filteredApp.length) {
      const filteredAppDetail = filteredApp[0];
      setEditionList(filteredAppDetail.editions);
      setCurrentApplication(filteredAppDetail);
      setSelectedApp(event.target.value);
    }
  };

  const handleEditionChange = (event: SelectChangeEvent) => {
    setEditionSelected(true);
    const editionUUID = event.target.value;
    if (
      currentApplication &&
      currentApplication.editions !== undefined &&
      currentApplication.editions.length
    ) {
      const filteredEdition = currentApplication.editions.filter(
        (ed: Edition): boolean => ed.uuid === editionUUID
      );
      const filteredEditionDetail = filteredEdition.length
        ? filteredEdition[0]
        : {};
      setCurrentEdition(filteredEditionDetail);
      setSelectedEditionValue(event.target.value);
    }
  };

  const ITEM_HEIGHT = 32;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <>
      <div className={styles.select}>
        {applications && applications.length > 0 ? (
          <FormControl
            className={`${commonStyles.width100} ${styles.appSelect}`}
          >
            <InputLabel id="select-application-label">
              Select Application
            </InputLabel>
            <Select
              labelId="select-application-label"
              id="select-application"
              value={selectedApp}
              label="Select Application"
              onChange={handleApplicationChange}
              MenuProps={MenuProps}
            >
              {(applications || []).map((application) => (
                <MenuItem key={application.uuid} value={application.uuid}>
                  <span>{application.name}</span>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <div className={styles.errMessage}>
            <p>
              It looks like you have not been granted permissions to view any
              applications or application editions in the system. <br></br>{" "}
              Please contact one of the system administrators via{" "}
              {SUPPORT_TEAM_EMAIL} .
            </p>
          </div>
        )}
      </div>

      {appSelected && editions && editions.length > 0 ? (
        <div className={commonStyles.mt2}>
          <FormControl 
            className={`${commonStyles.width100} ${styles.editionSelect}`}>
            <InputLabel id="select-edition-label">Select Edition</InputLabel>
            <Select
              labelId="select-edition-label"
              id="select-edition"
              value={selectedEditionValue}
              label="Select Edition"
              onChange={handleEditionChange}
              MenuProps={MenuProps}
            >
              {(editions || []).map((edition) => (
                <MenuItem key={edition.uuid} value={edition.uuid}>
                  {edition.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      ) : appSelected ? (
        <div className={styles.errMessage}>
          <p>
            It looks like you have not been granted permissions to view any
            applications or application editions in the system. <br></br>Please
            contact one of the system administrators via {SUPPORT_TEAM_EMAIL} .
          </p>{" "}
        </div>
      ) : (
        <p></p>
      )}
      {editionSelected && selectedEditionValue && (
        <div className={commonStyles.btnContainer}>
          <BaseButton
            disabled={!editionSelected}
            color="primary"
            variant="contained"
            text="Proceed"
            handleClick={handleSubmit}
            icon={<ChevronRightSharp />}
          ></BaseButton>
        </div>
      )}
    </>
  );
};

export default Home;
