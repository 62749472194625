import { FC } from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "../../pages/Home/HomePage";
import { LogoutPage, PreLogoutPage } from "../../pages/Logout";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import Users from "../../pages/Users";
import Articles from "../../pages/Articles/Articles";
import Login from "../../pages/Login";
import withMain from '../../hoc/withMain';
import AuthGuard from '../AuthGuardedRoute';
import Assets from "../../pages/Assets";

type Props = {
  isLoading?: boolean;
};

const Application: FC<Props> = ({ isLoading }: Props): JSX.Element => {
  
  return (
    <>
      <Routes>
        <Route path="/" element={<MsalAuthenticationTemplate interactionType={InteractionType.Redirect}><HomePage isLoading={isLoading} /></MsalAuthenticationTemplate>} />
        <Route path="/home" element={<MsalAuthenticationTemplate interactionType={InteractionType.Redirect}><HomePage /></MsalAuthenticationTemplate>} />
        <Route path="/login" element={<MsalAuthenticationTemplate interactionType={InteractionType.Redirect}><Login /></MsalAuthenticationTemplate>} />
        <Route path="/pre-logout" element={<PreLogoutPage />} />
        <Route path="/logout" element={<LogoutPage />} />

        {/* Protected Routes */}
        <Route path="/users" element={<MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          <AuthGuard component={<Users />} />
        </MsalAuthenticationTemplate>} />
        <Route path="/articles" element={<MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          <AuthGuard component={<Articles />} />
        </MsalAuthenticationTemplate>} />
        <Route path="/assets" element={<MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          <AuthGuard component={<Assets />} />
        </MsalAuthenticationTemplate>} />

      </Routes>
    </>
  );
};

export default withMain(Application);
